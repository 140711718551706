import classes from "./PrivacyPage.module.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const PrivacyPage = () => {
  return (
    <div className={classes.background}>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>
            Privacy Notice
          </title>
          <meta
            name="description"
            content="Guitar Charts Designer is a web app that lets you create and print custom guitar charts and diagrams."
          />
        </Helmet>
      </HelmetProvider>
      <div className={classes["a4-paper"]}>
        <div className={classes["content"]}>
          <h1>Privacy Notice</h1>
          <p>
            We take your privacy very seriously and are committed to protecting
            your personal information. This Privacy Notice explains how we
            collect, use, and disclose your personal information when you use
            our website.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
            When you use our website, we may collect the following types of
            personal information:
          </p>
          <ul>
            <li>
              Contact information, such as your name and email address, when you
              contact us or sign up for our newsletter
            </li>
            <li>
              Usage data, such as your IP address and browser type, when you
              interact with our website
            </li>
            <li>
              Cookies and other tracking technologies, which may collect
              information about your use of our website
            </li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We use the personal information we collect to:</p>
          <ul>
            <li>Provide and improve our services</li>
            <li>Communicate with you about our services and promotions</li>
            <li>Personalize your experience on our website</li>
            <li>Analyze and improve our website and services</li>
            <li>Comply with legal obligations</li>
          </ul>
          <p>
            We may also share your personal information with our trusted
            partners and service providers, but only to the extent necessary to
            provide our services and improve your experience on our website.
          </p>

          <h2>3. Cookies and Other Tracking Technologies</h2>
          <p>
            Our website may use cookies and other tracking technologies to
            collect information about your use of our website. You can choose to
            disable cookies in your browser settings, but please note that this
            may affect your ability to use our website.
          </p>

          <h2>4. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>
              Access your personal information and request that we correct,
              update, or delete it
            </li>
            <li>Object to the processing of your personal information</li>
            <li>
              Withdraw your consent to the processing of your personal
              information
            </li>
          </ul>
          <p>
            To exercise any of these rights, please contact us using the contact
            information provided on our website.
          </p>

          <h2>5. Security</h2>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access, use, and disclosure. However, please note
            that no method of transmitting or storing data is completely secure,
            and we cannot guarantee the absolute security of your personal
            information.
          </p>

          <h2>6. Changes to This Privacy Notice</h2>
          <p>
            We may update this Privacy Notice from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. We encourage you to review this Privacy Notice
            periodically to stay informed about how we are protecting your
            personal information.
          </p>

          <h2>7. Contact Us</h2>
          <p>
            If you have any questions or concerns about our Privacy Notice,
            please contact us at jambole0192 @ gmail.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
