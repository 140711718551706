import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { mainActions } from ".";
import { useEffect } from "react";
import { compressUrlSafe } from "../components/code/lzma-url.mjs";

//import Pako from "pako";

/* Dont know if this is a hack or not, but If I create
a dummy component then I can query the data without upsetting
the rest of the app.
*/
const PickleToQueryString = () => {
  const intervals = useSelector((state) => state.intervals);
  const midiRootNote = useSelector((state) => state.midiRootNote);
  const intervalColors = useSelector((state) => state.intervalColors);
  const tuning = useSelector((state) => state.tuning);
  const guitarSetup = useSelector((state) => state.guitarSetup);
  const title = useSelector((state) => state.title);
  const introduction = useSelector((state) => state.introduction);
  const imageScale = useSelector((state) => state.imageScale);
  const selectNps = useSelector((state) => state.selectNps);
  const refineData = useSelector((state) => state.refineData);

  const dispatch = useDispatch();

  // replace true and false with 't' and 'f'
  const compressedIntervals = intervals.map((value) => (value ? "t" : "f"));

  const pickle = {
    i: compressedIntervals,
    mr: midiRootNote,
    ic: intervalColors,
    gs: guitarSetup,
    t: tuning,
    tt: title,
    in: introduction,
    is: imageScale,
    nps: selectNps,
    rd: JSON.stringify(refineData),
  };

  const args = queryString.stringify(pickle);

  useEffect(() => {
    const compressed = "comp="+compressUrlSafe(args);
    dispatch(mainActions.setQueryString(compressed));
  }, [args,dispatch]);

  //const compressedArgs = btoa(Pako.deflate(args, { to: "string" }));
  //console.log(args);
  //console.log(args.length);
  //console.log(compressedArgs);
  //console.log(compressedArgs.length);


  return <div></div>;
};

export default PickleToQueryString;
