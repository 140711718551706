import queryString from "query-string";
import { mainActions } from ".";
import { decompressUrlSafe } from "../components/code/lzma-url.mjs";


const validateArray = (obj, name, length) => {
  if (name in obj && obj[name].length === length) return true;

  //console.log(`validateArray(${name},${length}) failed`);
  //console.log(obj);
  return false;
};

const UnpickleFromQueryString = (stringArgs, dispatch) => {
  let obj = queryString.parse(stringArgs);

  if ("comp" in obj) {
    // decompress it
    obj = decompressUrlSafe(obj["comp"]);

    // convert the result back into an object, then process as normal
    obj = queryString.parse(obj);
  }

  // make sure the elements are the right structure
  if (!validateArray(obj, "i", 12)) return;
  if (!validateArray(obj, "ic", 12)) return;
  if ("t" in obj) {
    if (obj["t"].length < 2 && obj["t"].length > 20) return;
  }

  // unpack the intervals true and false values from the t and f characters
  const intervals = obj.i.map((ch) => (ch === "t" ? true : false));
  console.log(obj);

  dispatch(mainActions.setAllIntervals(intervals));
  dispatch(mainActions.setAllIntervalColors(obj.ic));
  dispatch(mainActions.setGuitarSetup(obj.gs));

  // integers are converted into strings during the round trip, so convert them back.
  const tuning = obj.t.map(i=> +i);
  dispatch(mainActions.setCustomTuning(tuning));

  // midi root note was a late arrival so work around it 
  if("mr" in obj) {
    dispatch(mainActions.setMidiRootNote(+obj.mr));
  } else {
    dispatch(mainActions.setMidiRootNote(40));
  }
  dispatch(mainActions.setTitle(obj.tt));
  dispatch(mainActions.setIntroduction(obj.in));
  dispatch(mainActions.setImageScale(+obj.is));
  dispatch(mainActions.setSelectNps(obj.nps));

  const rd = JSON.parse(obj.rd);
  dispatch(mainActions.setRefineData(rd));
};

export default UnpickleFromQueryString;
