class Svg {
  constructor(isHorizontal, width, height) {
    this.isHorizontal = isHorizontal;
    this.width = width;
    this.height = height;
    this.styles = {};
  }
  addStyle = (styleData) => {
    /* the data in styles is stored back to front
    so this.styles[styleData] = stylekey
    */
    if (styleData in this.styles) {
      return this.styles[styleData];
    } else {
      const values = Object.values(this.styles);
      for (let n = 0; true; n++) {
        const key = `k${n}`;
        if (!values.includes(key)) {
          this.styles[styleData] = key;
          return key;
        }
      }
    }
  };
  getStyleHtml = () => {
    const styleTextList = Object.entries(this.styles).map(([key, value]) => {
      return `.${value} ${key}`;
    });
    return `<style>${styleTextList.join(" ")}</style>`;
  };
  canvas = (w, h) => {
    const lw = this.isHorizontal ? w : h;
    const lh = this.isHorizontal ? h : w;
    const key = this.addStyle(`{stroke:#fff; fill: #fff;}`);
    return `<rect x="0" y="0"  width="${lw}" height="${lh}" class="${key}"/>`;
  };

  line = (x1, y1, x2, y2) => {
    const lx1 = this.isHorizontal ? x1 : this.height - y1;
    const lx2 = this.isHorizontal ? x2 : this.height - y2;
    const ly1 = this.isHorizontal ? y1 : x1;
    const ly2 = this.isHorizontal ? y2 : x2;
    const key = this.addStyle(`{stroke:#000; stroke-width:2}`);
    return `<line x1="${lx1}" y1="${ly1}" x2="${lx2}" y2="${ly2}" class="${key}"/>`;
  };

  rect = (x1, y1, width, height, fill = "#fff") => {
    const lx1 = this.isHorizontal ? x1 : this.height - y1 - height;
    const ly1 = this.isHorizontal ? y1 : x1;
    const lwidth = this.isHorizontal ? width : height;
    const lheight = this.isHorizontal ? height : width;
    const key = this.addStyle(`{stroke:#000; fill:${fill}; stroke-width:2;}`);

    return `<rect x="${lx1}" y="${ly1}" width="${lwidth}" height="${lheight}" class="${key}"/>`;
  };

  circle = (x, y, r, stroke, fill, id) => {
    const lx = this.isHorizontal ? x : this.height - y;
    const ly = this.isHorizontal ? y : x;
    const key = this.addStyle(
      `{stroke:${stroke}; fill:${fill}; stroke-width:2;}`
    );
    return `<circle id="c${id}" cx="${lx}" cy="${ly}" r="${r}" class="${key}"/>`;
  };

  text = (x, y, txt) => {
    const lx = this.isHorizontal ? x : this.height - y;
    const ly = this.isHorizontal ? y : x;
    // use this to track the note name of the first
    // note in each string.
    const key = this.addStyle(`{font: bold 8px sans-serif;}`);
    return `<text x="${lx}" y="${ly}" class="${key}">${txt}</text>`;
  };
}

//==============================================================
// showNotes: 0 = All notes
//            1 = All roots
//            2 = Low Root
//            3 = High Root

const ChartDataToSVG = (chartdata, maxFret, showNotes = 0) => {
  const scale = 2;
  const string_space = 10 * scale;
  const fret_space = 20 * scale;
  const circle_size = 4 * scale;
  const border = 10 * scale;
  const strings = chartdata.length;
  const frets = maxFret < 5 ? 5 : maxFret + 1;

  const canvasWidth = border * 2 + fret_space * frets;
  const canvasHeight = border * 2 + string_space * (strings - 1);
  const isHorizontal = true;
  const svg = new Svg(isHorizontal, canvasWidth, canvasHeight);
  let myData = svg.canvas(canvasWidth, canvasHeight);
  myData += svg.rect(
    border,
    border,
    fret_space * frets,
    string_space * (strings - 1)
  );
  // Draw the strings
  const width = frets * fret_space + border;
  for (let s = 0; s < strings; s++) {
    let ypos = border + s * string_space;
    myData += svg.line(border, ypos, width, ypos);
  }

  // Draw The Frets
  const height = border + (strings - 1) * string_space;
  for (let f = 0; f < frets + 1; f++) {
    const xpos = border + fret_space * f;
    myData += svg.line(xpos, border, xpos, height);
  }

  let id = 0;
  if (showNotes === 0) {
    for (let s = 0; s < chartdata.length; s++) {
      const string = chartdata.length - s;
      for (let n = 0; n < chartdata[s].length; n++) {
        const fret = chartdata[s][n][0];
        const ypos = border + (string - 1) * string_space;
        const xpos = border + fret_space / 2 + fret * fret_space;
        myData += svg.circle(
          xpos,
          ypos,
          circle_size,
          "#000",
          chartdata[s][n][1],
          id
        );
        id += 1;
      }
    }
  } else if (showNotes === 1) {
    for (let s = 0; s < chartdata.length; s++) {
      const string = chartdata.length - s;
      for (let n = 0; n < chartdata[s].length; n++) {
        const fret = chartdata[s][n][0];
        const ypos = border + (string - 1) * string_space;
        const xpos = border + fret_space / 2 + fret * fret_space;

        if (chartdata[s][n][1] === "#000000") {
          myData += svg.circle(
            xpos,
            ypos,
            circle_size,
            "#000",
            chartdata[s][n][1],
            id
          );
        }
        id += 1;
      }
    }
  } else if (showNotes === 2) {
    let finished = false;
    for (let s = 0; s < chartdata.length; s++) {
      const string = chartdata.length - s;
      for (let n = 0; n < chartdata[s].length; n++) {
        const fret = chartdata[s][n][0];
        const ypos = border + (string - 1) * string_space;
        const xpos = border + fret_space / 2 + fret * fret_space;

        if (chartdata[s][n][1] === "#000000") {
          myData += svg.circle(
            xpos,
            ypos,
            circle_size,
            "#000",
            chartdata[s][n][1],
            id
          );
          finished = true;
          break;
        }
        id += 1;
      }
      if (finished) break;
    }
  } else if (showNotes === 3) {
    let finished = false;
    for (let s = chartdata.length - 1; s >= 0; s--) {
      const string = chartdata.length - s;
      for (let n = 0; n < chartdata[s].length; n++) {
        const fret = chartdata[s][n][0];
        const ypos = border + (string - 1) * string_space;
        const xpos = border + fret_space / 2 + fret * fret_space;

        if (chartdata[s][n][1] === "#000000") {
          myData += svg.circle(
            xpos,
            ypos,
            circle_size,
            "#000",
            chartdata[s][n][1],
            id
          );
          finished = true;
          break;
        }
        id += 1;
      }
      if (finished) break;
    }
  }

  const opWidth = isHorizontal ? canvasWidth : canvasHeight;
  const opHeight = isHorizontal ? canvasHeight : canvasWidth;
  const svgData =
    `<svg viewBox="0 0 ${opWidth} ${opHeight}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">` +
    svg.getStyleHtml() +
    myData +
    "</svg>\n";

  const retval = {
    width: Math.round(opWidth * 1.2),
    height: Math.round(opHeight * 1.2),
    image: svgData,
    frets: frets,
    strings: chartdata.length,
  };
  return retval;
};

//==============================================================

const midiPitchNameSharps = [
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
  "A",
  "A#",
  "B",
];
const midiPitchNameFlats = [
  "C",
  "D\u266D",
  "D",
  "E\u266D",
  "E",
  "F",
  "G\u266D",
  "G",
  "A\u266D",
  "A",
  "B\u266D",
  "B",
];

const ChartDataToFretboardSVG = (
  tuning,
  midiRootNote,
  lowestFret,
  maxFret,
  showPitch,
  showNoteNames,
  useSharps
) => {
  const scale = 2;
  const string_space = 10 * scale;
  const fret_space = 20 * scale;
  const circle_size = 4 * scale;
  const border = 10 * scale;
  const strings = tuning.length + 1;
  const frets = maxFret;

  // show the notes at the nut
  const startFret = lowestFret === 0 ? 1 : 0;
  const startFretOffset = startFret * fret_space;

  const canvasWidth = border * 2 + fret_space * frets;
  const canvasHeight = border * 2 + string_space * (strings - 1);
  const isHorizontal = true;
  const svg = new Svg(isHorizontal, canvasWidth, canvasHeight);
  let myData = svg.canvas(canvasWidth, canvasHeight);

  myData += svg.rect(
    border + startFretOffset,
    border,
    fret_space * (frets - startFret),
    string_space * (strings - 1)
  );

  // Draw the markers
  const markerFrets = [3, 5, 7, 9, 12, 15, 17, 19, 24];
  for (let f = 0; f < maxFret; f++) {
    if (markerFrets.includes(f + lowestFret)) {
      const xpos = border + fret_space / 2 + f * fret_space;
      const myGray = "#808080";
      if ((f + lowestFret) % 12) {
        const ypos = canvasHeight / 2;
        myData += svg.circle(
          xpos,
          ypos,
          circle_size / 2,
          myGray,
          myGray,
          1000 + f
        );
      } else {
        const ypos = canvasHeight / 3;
        myData += svg.circle(
          xpos,
          ypos,
          circle_size / 2,
          myGray,
          myGray,
          1000 + f
        );
        myData += svg.circle(
          xpos,
          ypos * 2,
          circle_size / 2,
          myGray,
          myGray,
          1000 + f
        );
      }
    }
  }

  // Draw the strings
  const width = frets * fret_space + border;
  for (let s = 0; s < strings; s++) {
    let ypos = border + s * string_space;
    myData += svg.line(border + startFret * fret_space, ypos, width, ypos);
  }

  if (lowestFret === 0) {
    // draw the nut
    myData += svg.rect(
      startFretOffset + border - 2,
      border,
      4,
      string_space * (strings - 1),
      "#000"
    );

    // draw some markers for the open strings
    for (let s = 0; s < tuning.length + 1; s++) {
      const ypos = border + s * string_space;
      const xpos = border + fret_space / 2;

      myData += svg.circle(xpos, ypos, circle_size, "grey", "#fff", s);
    }
  } else if (lowestFret === 1) {
    // draw the nut
    myData += svg.rect(
      border - 2,
      border,
      4,
      string_space * (strings - 1),
      "#000"
    );
  } else {
    const x = fret_space + border - 8;
    const y = border + string_space * (strings - 1) + 12;
    myData += svg.text(x, y, `${lowestFret} fr`);
    myData += svg.text(x, border - 4, `${lowestFret} fr`);
  }

  // Draw The Frets
  const height = border + (strings - 1) * string_space;
  for (let f = startFret; f < frets + 1; f++) {
    const xpos = border + fret_space * f;
    myData += svg.line(xpos, border, xpos, height);
  }

  let stringStartNote = midiRootNote + lowestFret;
  let id = 0;
  for (let s = 0; s < tuning.length + 1; s++) {
    const string = tuning.length - s;
    for (let fret = 0; fret < frets; fret++) {
      const ypos = border + string * string_space;
      const xpos = border + fret_space / 2 + fret * fret_space;

      const note = (stringStartNote + fret) % 12;

      if (showNoteNames) {
        if (showPitch[note]) {
          myData += svg.circle(xpos, ypos, circle_size, "#000", "#fff", id);

          const pos = 1.5;
          const midiPitchNames = useSharps
            ? midiPitchNameSharps
            : midiPitchNameFlats;

          const txt = midiPitchNames[note];
          const txtOffset = txt.length === 1 ? 1 : 1.8;
          const x = xpos - scale * pos * txtOffset;
          myData += svg.text(x, ypos + scale * pos, txt);
        }
        id++;
      }
    }
    if (s < tuning.length) {
      stringStartNote += tuning[s];
    }
  }
  const opWidth = isHorizontal ? canvasWidth : canvasHeight;
  const opHeight = isHorizontal ? canvasHeight : canvasWidth;
  const svgData =
    `<svg viewBox="0 0 ${opWidth} ${opHeight}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">` +
    svg.getStyleHtml() +
    myData +
    "</svg>\n";

  // don't know why the * 1.2 is needed
  const retval = {
    width: Math.round(opWidth * 1.2),
    height: Math.round(opHeight * 1.2),
    image: svgData,
    frets: frets,
    strings: strings,
  };

  return retval;
};

export { ChartDataToFretboardSVG };

export default ChartDataToSVG;
