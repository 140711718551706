import classes from "./LibraryPage.module.css";
import PublishScalePage from "../PublishScalePage/PublishScalePage";
import UnpickleFromQueryString from "../../store/UnpickleFromQueryString";
import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../store";
import { useEffect, useState } from "react";
import library from "./library";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LibraryMenu from "./LibraryMenu";

const findQueryStringFromSlug = (slug, liblist) => {
  for (let row = 0; row < liblist.length; row++) {
    if ("children" in liblist[row]) {
      let ret = findQueryStringFromSlug(slug, liblist[row].children);

      if (ret !== null) return ret;
    } else {
      if (slug === liblist[row].slug) {
        return liblist[row].queryString;
      }
    }
  }
  return null;
};

const LibraryPage = () => {
  const [isBurgerMenu, setIsBurgerMenu] = useState(true);

  const queryString = useSelector((state) => state.queryString);

  const dispatch = useDispatch();

  const burgerWidth = 1125;
  useEffect(() => {
    setIsBurgerMenu(window.innerWidth < burgerWidth);
  }, []);

  window.addEventListener("resize", () => {
    setIsBurgerMenu(window.innerWidth < burgerWidth);
  });

  useEffect(() => {
    let queryString = findQueryStringFromSlug(
      window.location.pathname.substring(1),
      library
    );

    // load something other than a blank screen
    if (queryString == null) {
      queryString =
        "gs=EADGBE&i=t&i=f&i=t&i=f&i=t&i=t&i=f&i=t&i=f&i=t&i=f&i=t&ic=%23000000&ic=%23ffffff&ic=%23ffffff&ic=%23ffffff&ic=%23ffffff&ic=%23ffffff&ic=%23ffffff&ic=%23ffffff&ic=%23ffffff&ic=%23ffffff&ic=%23ffffff&ic=%23ffffff&in=Create%20charts%20like%20these%20quickly%20and%20efficiently%21%0A%0AClick%20on%20%22%5BCreate%20Scale%20Charts%5D%28%2Fedit%29%22%20to%20start%20from%20scratch%2C%20or%20choose%20from%20the%20growing%20%5BLibrary%5D%28%2Flibrary%29%20of%20pre-built%20charts.%0A%0AYou%20can%20even%20customise%20this%20page%2C%20just%20%2A%2Aclick%2A%2A%20on%20one%20of%20the%20shapes%20below%20to%20start%21&is=75&nps=3nps&rd=%7B%22perChartData%22%3A%5B%7B%22notesPerString%22%3A%5B3%2C3%2C3%2C3%2C3%2C3%5D%2C%22notesOffset%22%3A0%7D%2C%7B%22notesPerString%22%3A%5B3%2C3%2C3%2C3%2C3%2C3%5D%2C%22notesOffset%22%3A1%7D%2C%7B%22notesPerString%22%3A%5B3%2C3%2C3%2C3%2C3%2C3%5D%2C%22notesOffset%22%3A3%7D%2C%7B%22notesPerString%22%3A%5B3%2C3%2C3%2C3%2C3%2C3%5D%2C%22notesOffset%22%3A5%7D%2C%7B%22notesPerString%22%3A%5B3%2C3%2C3%2C3%2C3%2C3%5D%2C%22notesOffset%22%3A6%7D%2C%7B%22notesPerString%22%3A%5B3%2C3%2C3%2C3%2C3%2C3%5D%2C%22notesOffset%22%3A8%7D%2C%7B%22notesPerString%22%3A%5B3%2C3%2C3%2C3%2C3%2C3%5D%2C%22notesOffset%22%3A10%7D%5D%7D&t=5&t=5&t=5&t=4&t=5&tt=Major%203nps%20Scales";
    }

    UnpickleFromQueryString(queryString, dispatch);

    // store the last query string
    dispatch(mainActions.setQueryString(queryString));
  }, [dispatch]);

  const onClick = () => {
    const url = `/publish/?${queryString}`;
    window.location = url;
  };

  // for the Burger Menu Button
  const [isOpen, setIsOpen] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes.container}>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Guitar Charts Designer - Library</title>
          <meta
            name="description"
            content="Guitar Charts Designer is a web app that lets you create and print custom guitar charts and diagrams."
          />
        </Helmet>
      </HelmetProvider>
      {isBurgerMenu === true && isOpen === true && (
        <div className={classes.mobileButtonContainer}><LibraryMenu onItemClicked={toggleMenu}/></div>
      )}
      {isBurgerMenu === false && (
        <div className={classes.leftColumn}>
          <LibraryMenu onItemClicked={toggleMenu}/>
        </div>
      )}
      <div className={classes.rightColumn}>
        <div className={classes.publishBorder}>
          <PublishScalePage onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default LibraryPage;
