import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../store";
import classes from "./EarTrainerSetup.module.css";

const EarTrainerShapeChangeSelect = () => {
  const tinyScreen = useSelector((state) => state.tinyScreen);
  const dispatch = useDispatch();
  const earTrainerShapeChange = useSelector(
    (state) => state.earTrainerShapeChange
  );

  const shapeChangeHandler = (event) => {
    dispatch(mainActions.setEarTrainerShapeChange(+event.target.value));
  };

  const nts = tinyScreen ? "" : " Notes";
  const evry = tinyScreen ? "" : "Every "
  return (
    <tr>
      <td className={classes.td}>
        <select
          value={earTrainerShapeChange}
          onChange={shapeChangeHandler}
          className={classes.select}
        >
          <option value="0">Never </option>
          <option value="1">{evry}1{nts}</option>
          <option value="2">{evry}2{nts}</option>
          <option value="4">{evry}4{nts}</option>
          <option value="8">{evry}8{nts}</option>
          <option value="16">{evry}16{nts}</option>
          <option value="32">{evry}32{nts}</option>
        </select>
      </td>
      <td>Randomly change <b>root</b> and <b>shape</b></td>
    </tr>
  );
};

export default EarTrainerShapeChangeSelect;
