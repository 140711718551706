import React, { useEffect, useState } from "react";

import classes from "./MenuBar.module.css";
import { useSelector } from "react-redux";
import Button from "../UI/Button";
import Submenu from "./Submenu";

// props.selected=0 to 5
// props.onMenuClick(selection)
const MenuBar = ({ page, selected, onMenuClick }) => {
  // get query string for publishPage
  const queryString = useSelector((state) => state.queryString);
  const [isBurgerMenu, setIsBurgerMenu] = useState(false);

  const burgerWidth = 940;
  useEffect(() => {
    setIsBurgerMenu(window.innerWidth < burgerWidth);
  }, []);

  window.addEventListener("resize", () => {
    setIsBurgerMenu(window.innerWidth < burgerWidth);
  });

  const onButtonClickHandler = (event) => {
    console.log(window.location);
    if (window.location.pathname !== "/") {
      window.location = "/";
    }
    onMenuClick(+event.target.id);
  };

  const onLoadCurrentPage = (event) => {
    const url = `/${event.target.id}`;
    if (window.location.pathname !== url) {
      window.location = url;
    }
  };

  const loadPublishInNewPage = () => {
    const url = `/publish?${queryString}`;
    window.open(url);
    //window.location = url;
  };

  const selectedAttr = { backgroundColor: "#258", color: "white" };
  const normalAttr = { backgroundColor: "white", color: "black" };

  const homeState =
    page === "home" && selected === 1 ? selectedAttr : normalAttr;
  const libraryState = page === "library" ? selectedAttr : normalAttr;
  const publishState = page === "publish" ? selectedAttr : normalAttr;
  const supportState = page === "support" ? selectedAttr : normalAttr;
  const refineState =
    page === "home" && selected === 2 ? selectedAttr : normalAttr;
  const previewState =
    page === "home" && selected === 3 ? selectedAttr : normalAttr;
  const trainingState = page === "training" ? selectedAttr : normalAttr;
  const fretboardState = page === "fretboard" ? selectedAttr : normalAttr;
  const blogState = page === "blog" ? selectedAttr : normalAttr;

  // const downloadState =    page === "edit" && selected === 4 ?  selectedAttr : normalAttr;

  const showAfterCreate = page === "home";
  const showAfterRefine = page === "home" && selected > 1;

  // for the Burger Menu Button
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuItems = (
    <>

      <Button id="1" onClick={onButtonClickHandler} style={homeState}>
        Home
      </Button>
      {showAfterCreate && (
        <Button id="2" style={refineState} onClick={onButtonClickHandler}>
          Refine Scale Charts
        </Button>
      )}
      {showAfterRefine && (
        <>
          <Button id="3" style={previewState} onClick={onButtonClickHandler}>
            Preview
          </Button>
          <Button
            id="publish"
            style={publishState}
            onClick={loadPublishInNewPage}
          >
            Publish
          </Button>
        </>
      )}
      <Submenu isBurger={isBurgerMenu} title={"Training..."}>
        <Button id="training" style={trainingState} onClick={onLoadCurrentPage}>
          Ear Training
        </Button>
        <Button id="fretboard" style={fretboardState} onClick={onLoadCurrentPage}>
          Fretboard Training
        </Button>
      </Submenu>
      <Button id="library" style={libraryState} onClick={onLoadCurrentPage}>
        Library
      </Button>
      <Button id="blog" style={blogState} onClick={onLoadCurrentPage}>
        Blog
      </Button>
      <Button id="support" style={supportState} onClick={onLoadCurrentPage}>
        Support Me
      </Button>

    </>
  );

  return (
    <>
      <header className={classes.header}>
        {isBurgerMenu && (
          <button
            className={`${classes["burger-menu"]} ${
              isOpen ? classes.open : ""
            }`}
            onClick={toggleMenu}
          >
            <div className={classes.bar}></div>
            <div className={classes.bar}></div>
            <div className={classes.bar}></div>
          </button>
        )}
        {isBurgerMenu === false && (
          <div className={classes.buttonContainer}>{menuItems}</div>
        )}
      </header>
      {isBurgerMenu === true && isOpen === true && (
        <div className={classes.mobileButtonContainer}>{menuItems}</div>
      )}
    </>
  );
};

export default MenuBar;
