import classes from './Card.module.css';

function Card(props) {
    let card = classes.card; //  + " " + props.className;
    if (props.className) {
        card = card + ' ' + props.className;
    }
    
    return <div className={card}>{props.children}</div>
}

export default Card;