

import IntervalRow from "./IntervalRow/IntervalRow";
import IntervalColors from "./IntervalColors/IntervalColors";

import classes from "./ChartEditor.module.css";
import GuitarSetup from "./GuitarSetup/GuitarSetup";
import SelectNPS from "./SelectNPS/SelectNPS";
import CommonScalePicker from "./CommonScalePicker/CommonScalePicker";
import { useSelector } from "react-redux";

// props.intervalColors = array of color values
const ChartEditor = (props) => {
  const tinyScreen = useSelector((state) => state.tinyScreen);

  return (
    <>
      <table className={classes.tableLayout}>
        <tbody>
          <GuitarSetup />
          <CommonScalePicker />
          <tr>
            <td>{tinyScreen ? "Ints" : "Intervals"}</td>
            <td>1</td>
            <td></td>
            <td>2</td>
            <td></td>
            <td>3</td>
            <td>4</td>
            <td></td>
            <td>5</td>
            <td></td>
            <td>6</td>
            <td></td>
            <td>7</td>
          </tr>
          <IntervalRow />
          <IntervalColors  />
          <SelectNPS  />
        </tbody>
      </table>
    </>
  );
};

export default ChartEditor;
