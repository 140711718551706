const GeneratePosition = (tuning, notesPerString, s) => {
  let strings = [[]];

  // 0 is the lowest string 5 is highest
  let guitarString = 0;
  let stringOffset = 0;
  let noteCount = 0;
  let lowestFret = 110;
  for (let n = 0; n < s.length; n++) {
    if (s[n] === "") {
      continue;
    }

    let exitLoop = false;
    while (noteCount >= notesPerString[guitarString]) {
      stringOffset += tuning[guitarString];
      guitarString++;
      if (guitarString >= notesPerString.length) {
        exitLoop = true;
        break;
      }
      // set up for the next string
      strings.push([]);
      noteCount = 0;
    }
    if (exitLoop) break;

    let pos = n - stringOffset;
    if (pos < lowestFret) {
      lowestFret = pos;
    }
    strings[guitarString].push([pos, s[n]]);
    noteCount++;
  }

  // now add an offset to normalise the lowest fret to 0
  let maxfrets = 0;
  for (let n = 0; n < strings.length; n++) {
    for (let f = 0; f < strings[n].length; f++) {
      const fret = strings[n][f][0] - lowestFret;
      strings[n][f][0] = fret;
      if (fret > maxfrets) {
        maxfrets = fret;
      }
    }
  }
  return { maxFret: maxfrets, chartData: strings, lowestFret: lowestFret };
};

export default GeneratePosition;
