import classes from "./EarTrainerSetup.module.css";
import EarTrainerChartSelect from "./EarTrainerChartSelect";
import EarTrainerRootSelect from "./EarTrainerRootSelect";
import EarTrainerShowNotesSelect from "./EarTrainerShowNotesSelect";
import EarTrainerQuestionTypeSelect from "./EarTrainerQuestionTypeSelect";
import EarTrainerShapeChangeSelect from "./EarTrainerShapeChangeSelect";
import EarTrainerStartTrainingSelect from "./EarTrainerStartTrainingSelect";
import EarTrainerIgnoreOctavesSelect from "./EarTrainerIgnoreOctavesSelect";
import EarTrainerRootOctaveSelect from "./EarTrainerRootOctaveSelect";

const EarTrainerSetup = ({ onHearRoot, onHearQuestion }) => {
  return (
    <table className={classes.tableLayout}>
      <tbody>
        <EarTrainerChartSelect />
        <EarTrainerRootSelect />
        <EarTrainerRootOctaveSelect />
        <EarTrainerShowNotesSelect />
        <EarTrainerQuestionTypeSelect />
        <EarTrainerIgnoreOctavesSelect />
        <EarTrainerShapeChangeSelect />
        <EarTrainerStartTrainingSelect />
      </tbody>
    </table>
  );
};

export default EarTrainerSetup;
