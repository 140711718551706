function highlightClick(x, y) {
    // get the position of the click relative to the document
    //const x = event.pageX - 5;
    //const y = event.pageY - 5;
    const halfCircleSize = 20 / 2;

    // create a new element to represent the circle
    const circle = document.createElement("div");

    // set the style for the circle element
    circle.style.position = "absolute";
    circle.style.left = x - halfCircleSize + "px";
    circle.style.top = y - halfCircleSize + "px";
    circle.style.width = "20px";
    circle.style.height = "20px";
    circle.style.borderRadius = "50%";
    circle.style.background = `radial-gradient(circle at center, red 0%, rgba(255, 0, 0, 0) 70%)`;

    // add the circle element to the DOM
    document.body.appendChild(circle);

    // remove the circle element after 1 second
    setTimeout(() => {
      document.body.removeChild(circle);
    }, 500);
  }

  export default highlightClick;
  