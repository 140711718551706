import classes from "./BottomMenu.module.css";

const BottomMenu = () => {

  const onClickPrivacy = () => {
    window.location = "/privacy";
  };

  const onClickTerms = () => {
    window.location = "/terms";
  };

  return (
    <div className={classes.container}>
      <button className={classes.button} onClick={onClickPrivacy}>Privacy</button>
      <button className={classes.button} onClick={onClickTerms}>Terms Of Use</button>
    </div>
  );
};

export default BottomMenu;
