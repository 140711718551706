import { useSelector } from "react-redux";
import RefineDialog from "./RefineDialog/RefineDialog";
import classes from "./RefineScalePage.module.css";
import RefinePageDialog from "./RefinePageDialog/RefinePageDialog";
import BottomMenu from "../BottomMenu/BottomMenu";
import Card from "../UI/Card";
import RequestSupportCard from "../UI/RequestSupportCard";
import { Helmet, HelmetProvider } from "react-helmet-async";

const RefineScalePage = () => {
  const publishedData = useSelector((state) => state.publishedData);
  //console.log(publishedData);

  let maxImageWidth = 0;

  const elements = publishedData.map((perChart, index) => {
    const src = `data:image/svg+xml;utf8,${encodeURIComponent(perChart.image)}`;
    if (perChart.width > maxImageWidth) {
      maxImageWidth = perChart.width;
    }
    return (
      <div key={`key${index}`}>
        <RefineDialog
          src={src}
          stringCount={perChart.stringCount}
          index={index}
          width={perChart.width}
          height={perChart.height}
        />
      </div>
    );
  });

  maxImageWidth += 79;

  return (
    <div className={classes.background}>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Guitar Charts Designer</title>
          <meta
            name="description"
            content="Guitar Charts Designer is a web app that lets you create and print custom guitar charts and diagrams."
          />
        </Helmet>
      </HelmetProvider>

      <Card>
        <div className={classes.centred}>
          <h1 className={classes.h1}>Guitar Charts Designer - Refine</h1>
          <p className={classes.howtouselink}>
            <a href="#usage">How To Use...</a>
          </p>
        </div>
      </Card>
      <RequestSupportCard />
      <Card>
        <RefinePageDialog />
      </Card>
      <Card>
        <div
          className={classes.imageGrid}
          style={{
            //gridTemplateColumns: `repeat(auto-fit, minmax(${maxImageWidth}px, 1fr))`,
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          {elements}
        </div>
      </Card>
      <Card>
        <div className={classes.howToUse}>
          <h3 id="usage">How To Use</h3>
          <ol>
            <li>
              Give your Page a <b>Title</b>
            </li>
            <li>
              Add any <b>Introduction</b> text you choose using the{" "}
              <a
                href="https://commonmark.org/help/"
                target="_blank"
                rel="noreferrer"
              >
                Markdown for formatting
              </a>
              .
            </li>
            <li>
              Add and remove notes from each string using their <b> - + </b>{" "}
              buttons.
            </li>
            <li>
              <b>Delete</b> or <b>Duplicate</b> Charts using their buttons.
            </li>
            <li>
              <b>Preview</b> the final Chart.
            </li>
            <li>
              <b>Publish</b> to create a Bookmark-able, Linkable, Printable Page
              that you can save and use as you want.
            </li>
          </ol>
        </div>
      </Card>
      <Card>
        <BottomMenu />
      </Card>
    </div>
  );
};

export default RefineScalePage;
