import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import classes from "./GuitarSetup.module.css";
import { mainActions } from "../../../../store";

const notes = [
  ["B0", 23],
  ["C1", 24],
  ["C#1", 25],
  ["D1", 26],
  ["D#1", 27],
  ["E1", 28],
  ["F1", 29],
  ["F#1", 30],
  ["G1", 31],
  ["G#1", 32],
  ["A1", 33],
  ["A#1", 34],
  ["B1", 35],
  ["C2", 36],
  ["C#2", 37],
  ["D2", 38],
  ["D#2", 39],
  ["E2", 40],
  ["F2", 41],
  ["F#2", 42],
  ["G2", 43],
  ["G#2", 44],
  ["A2", 45],
  ["A#2", 46],
  ["B2", 47],
  ["C3", 48],
  ["C#3", 49],
  ["D3", 50],
  ["D#3", 51],
  ["E3", 52],
  ["F3", 53],
  ["F#3", 54],
  ["G3", 55],
];

const GuitarSetup = () => {
  const tinyScreen = useSelector((state) => state.tinyScreen);

  const guitarSetup = useSelector((state) => state.guitarSetup);
  const midiRootNote = useSelector((state) => state.midiRootNote);
  const tuning = useSelector((state) => state.tuning);
  const [tuningEnable, setTuningEnable] = useState(guitarSetup === "custom");
  const [tuningStr, setTuningStr] = useState(tuning);
  const [inputErr, setInputErr] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTuningEnable(guitarSetup === "custom");
  }, [guitarSetup]);

  useEffect(() => {
    setTuningStr(tuning);
  }, [tuning]);

  const selectOnChangeHandler = (event) => {
    setInputErr(false);
    dispatch(mainActions.setGuitarSetup(event.target.value));
  };

  const midiOptions = notes.map((item) => {
    return (
      <option key={item[1]} value={item[1]}>
        {item[0]}
      </option>
    );
  });

  const midiRootNoteOnChangeHandler = (event) => {
    const value = +event.target.value;
    dispatch(mainActions.setMidiRootNote(value));
  };

  const tuningOnChangeHandler = (event) => {
    const value = event.target.value;
    const regex = /[^,\d]/;

    if (value === "") {
      setTuningStr(value);
      setInputErr(true);
    } else if (regex.value) {
      // the text has something other than digits and commas in it.
      return;
    } else if (value[value.length - 1] === ",") {
      // allow the character, but dont update the tuning array yet
      setTuningStr(value);
      setInputErr(true);
      return;
    } else {
      const newval = value.split(",").map(Number);
      setTuningStr(value);
      if (newval.includes(0) || newval.some(Number.isNaN)) {
        setInputErr(true);
        return;
      }
      setInputErr(false);

      if (JSON.stringify(newval) !== JSON.stringify(tuning)) {
        dispatch(mainActions.setCustomTuning(newval));
      }
    }
  };

  return (
    <>
      <tr className={classes.row}>
        <td className={classes.td}>{tinyScreen ? "Guit." : "Guitar Setup"}</td>
        <td colSpan={12} className={classes.td}>
          <select
            className={classes.select}
            value={guitarSetup}
            onChange={selectOnChangeHandler}
          >
            <option value="GCEA">
              Ukulele/ Baritone - 4 String eg. GCEA/ DGBE
            </option>
            <option value="EADG">Standard - 4 String Bass eg. EADG</option>
            <option value="BEADG">Standard - 5 String Bass eg. BEADG</option>
            <option value="EADGBE">
              Standard - 6 String Guitar eg. EADGBE
            </option>
            <option value="BEADGBE">
              Standard - 7 String Guitar eg. BEADGBE
            </option>
            <option value="FsBEADGBE">
              Standard - 8 String Guitar eg. F#BEADGBE
            </option>
            <option value="EADGCF">
              All 4ths - 6 String Guitar eg. EADGCF
            </option>
            <option value="CGDAEG">
              New Standard Tuning - 6 String Guitar eg. CGDAEG
            </option>
            <option value="AbEbBbFCG">
              All 5ths - 6 String Guitar eg. AbEbBbFCG
            </option>
            <option value="DADGBE">Drop D - 6 String Guitar eg. DADGBE</option>
            <option value="EBEADGBE">
              Drop E - 8 String Guitar eg. EBEADGBE
            </option>
            <option value="DADGBD">
              Double Drop D - 6 String Guitar eg. DADGBD
            </option>
            <option value="DADGAD">DADGAD - 6 String Guitar eg. DADGAD</option>
            <option value="DADFsAD">
              Open D/ E - 6 String Guitar eg. DADF#AD
            </option>
            <option value="DGDGBD">
              Open G/ A - 6 String Guitar eg. DGDGBD
            </option>
            <option value="custom">
              Custom Layout (define your own below)
            </option>
          </select>
        </td>
      </tr>
      {tuningEnable && (
        <tr>
          <td>Custom Layout</td>
          <td colSpan={2}>
            <select
              value={midiRootNote}
              className={classes.select}
              onChange={midiRootNoteOnChangeHandler}
            >
              {midiOptions}
            </select>
          </td>
          <td colSpan={10}>
            <input
              className={inputErr ? classes.inputError : classes.input}
              disabled={!tuningEnable}
              value={tuningStr}
              onChange={tuningOnChangeHandler}
            ></input>
          </td>
        </tr>
      )}
    </>
  );
};

export default GuitarSetup;
