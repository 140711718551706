import { useEffect, useRef } from "react";

// note E == 0

const DronePlayer = ({ note, keydown }) => {
  const audioContextRef = useRef(null);
  const sourceRef = useRef(null);

  useEffect(() => {
    const sampleUrl = "/samples/epad.mp3";
    const pitchRate = Math.pow(1.05946309436, note);

    //console.log("note", note, "pitchRate", pitchRate);

    // stop playing the sample
    const source = sourceRef.current;
    if (source) {
      source.stop();
      source.disconnect();
    }

    // close the AudioContext
    const audioContext = audioContextRef.current;
    if (audioContext) {
      audioContext.close();
    }

    // clear the references
    sourceRef.current = null;
    audioContextRef.current = null;

    if (keydown) {
      // create AudioContext
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();

      // load sample into an AudioBuffer
      const request = new XMLHttpRequest();
      request.open("GET", sampleUrl, true);
      request.responseType = "arraybuffer";
      request.onload = function () {
        audioContext.decodeAudioData(request.response, function (buffer) {
          // create AudioBufferSourceNode and connect to AudioContext output
          const source = audioContext.createBufferSource();
          source.buffer = buffer;
          source.loop = true;
          source.connect(audioContext.destination);

          // start playing the sample
          source.playbackRate.value = pitchRate;
          source.start();

          // store AudioContext and AudioBufferSourceNode references for later use
          audioContextRef.current = audioContext;
          sourceRef.current = source;
        });
      };
      request.send();
    }
  }, [note, keydown]);

  return null;
};

export default DronePlayer;
