import { useDispatch, useSelector } from "react-redux";
import TextToChartData from "../components/code/TextToChartData";
import { useEffect } from "react";
import CreateRefineScaleData from "../components/RefineScalePage/CreateRefineScaleData";
import { mainActions } from ".";
import GenerateExtendedScaleNotes from "../components/code/GenerateExtendedScaleNotes";
import GeneratePosition from "../components/code/GeneratePosition";
import ChartDataToSVG from "../components/code/ChartDataToSVG";

// takes
const RenderData = () => {
  const dispatch = useDispatch();

  const selectNps = useSelector((state) => state.selectNps);
  const intervals = useSelector((state) => state.intervals);
  const intervalColors = useSelector((state) => state.intervalColors);
  const tuning = useSelector((state) => state.tuning);
  const refineData = useSelector((state) => state.refineData);
  const createdData = useSelector((state) => state.createdData);
  const protectRefineData = useSelector((state) => state.protectRefineData);

  // Add event listener to update screen width on resize
  window.addEventListener("resize", () => {
    dispatch(mainActions.setTinyScreen(window.innerWidth < 437));
  });

  // This builds the initial chart data based on the CreateData Page
  useEffect(() => {
    // this is the best fit set of charts data
    const data = TextToChartData(tuning, intervals, intervalColors, selectNps);
    dispatch(mainActions.setCreatedData(data));
    
  }, [tuning, intervals, intervalColors, selectNps, dispatch]);

  // this rebuilds the Refined Scale data
  useEffect(() => {
    if (!protectRefineData) {
      CreateRefineScaleData(createdData, dispatch);
    }
  }, [createdData, dispatch, protectRefineData]);

  useEffect(() => {
    const s = GenerateExtendedScaleNotes(intervals, intervalColors);

    const svgList = refineData.perChartData.map((chartData) => {
      const pos = GeneratePosition(
        tuning,
        chartData.notesPerString,
        chartData.notesOffset > 0 ? s.slice(chartData.notesOffset) : s // can have negative notesOffset
      );
      const svg = ChartDataToSVG(pos.chartData, pos.maxFret, 0);

      // The layout of publishedData - each element
      const perChart = {
        image: svg.image,
        width: svg.width,
        height: svg.height,
        stringCount: tuning.length + 1,
        fretCount: svg.frets,
        chartData: pos.chartData,
        pitchOffset: chartData.notesOffset + pos.lowestFret,
      };

      return perChart;
    });

    dispatch(mainActions.setPublishedData(svgList));
  }, [intervals, intervalColors, tuning, refineData, dispatch]);

  return <div></div>;
};

export default RenderData;
