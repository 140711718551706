import classes from "./PreviewScalePage.module.css";
import PublishScalePage from "./PublishScalePage";
import { Helmet, HelmetProvider } from "react-helmet-async";

const PreviewScalePage = (props) => {
  return (
    <div className={classes.container}>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>
            Guitar Charts Designer - Preview
          </title>
          <meta
            name="description"
            content="Guitar Charts Designer is a web app that lets you create and print custom guitar charts and diagrams."
          />
        </Helmet>
      </HelmetProvider>
      <div className={classes.scrollArea}>
        <PublishScalePage onClick={props.onClick}/>
      </div>
    </div>
  );
};

export default PreviewScalePage;
