import React, { useState } from "react";
import Modal from "../UI/Modal";
import { SketchPicker } from "react-color";

// props.onClose(color) = Dialog closed
// props.color = the starting color
const ColorPickerDialog = (props) => {
  const [color, setColor] = useState(props.color);

  const onChangeCompleteHandler = (incolor) => {
    console.log("Selected color:", color, incolor);
    setColor(incolor.hex);
  };

  const onClose = () => {
    props.onClose(color);
  }

  return (
    <Modal onClose={onClose}>
      <SketchPicker
        disableAlpha={true}
        color={color}
        onChangeComplete={onChangeCompleteHandler}
      />
    </Modal>
  );
};

export default ColorPickerDialog;
