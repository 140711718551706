import React, { useEffect, useState } from "react";
import classes from "./MenuBar.module.css";
import Button from "../UI/Button";

function convertToValidId(string) {
    const trimmedString = string.trim();
    const replacedSpaces = trimmedString.replace(/[\s]+/g, '_');
    const replacedSpecialChars = replacedSpaces.replace(/[^a-zA-Z0-9-_]/g, '');
    const lowercaseString = replacedSpecialChars.toLowerCase();
    return lowercaseString;
  }

const Submenu = ({ isBurger, title, children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const iD = convertToValidId(`${title}menuid`);

  useEffect(() => {
    if (isBurger) {
      setX(20);
      setY(63);
    } else {
      const siblingElement = document.querySelector(`#${iD}`);

      if (siblingElement) {
        const position = siblingElement.getBoundingClientRect();
        const scrollX = window.pageXOffset;
        const scrollY = window.pageYOffset;

        setX(position.x + scrollX);
        setY(position.y + scrollY + position.height);
        console.log("Position:", position);
      }
    }
  }, [iD, isBurger]);

  useEffect(() => {
    const handleClickOutside = (event) => {
/*
      setShowMenu((prev) => {
        if (prev) {
            console.log("clearing showMenu")
            return !prev;
        }
        return prev;
      });*/
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const menuTitleClick = () => {
    setShowMenu((prev) => !prev);
  };

  return (
    <>
      <Button
        onClick={menuTitleClick}
        id={iD}
        style={{ backgroundColor: "white", color: "black" }}
      >
        {title}
      </Button>
      {showMenu && (
        <div
          onClick={menuTitleClick}
          style={{
            position: "fixed",
            top: `0`,
            left: `0`,
            width: "100vw",
            height: "100vh",
          }}
        >
          <div
            style={{ position: "absolute", top: `${y}px`, left: `${x}px` }}
            className={classes.mobileButtonContainer}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Submenu;
