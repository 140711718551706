import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../store";
import classes from "./EarTrainerSetup.module.css";

const EarTrainerIgnoreOctavesSelect = () => {
  const tinyScreen = useSelector((state) => state.tinyScreen);
  const dispatch = useDispatch();
  const earTrainerIgnoreOctaves = useSelector(
    (state) => state.earTrainerIgnoreOctaves
  );
  const changeHandler = (event) => {
    dispatch(mainActions.setEarTrainerIgnoreOctaves(+event.target.value));
  };

  const normalOptions = (
    <>
      <option value="0">Check Octaves</option>
      <option value="1">Ignore Octaves</option>
    </>
  );

  const tinyOptions = (
    <>
      <option value="0">No</option>
      <option value="1">Yes</option>
    </>
  );

  return (
    <tr>
      <td className={classes.td}>
        <select
          value={earTrainerIgnoreOctaves}
          onChange={changeHandler}
          className={classes.select}
        >
            {tinyScreen ? tinyOptions : normalOptions}
        </select>
      </td>
      <td>Match octaves?</td>
    </tr>
  );
};

export default EarTrainerIgnoreOctavesSelect;
