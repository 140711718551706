import { useDispatch, useSelector } from "react-redux";
import GuitarSetup from "../../CreateScalePage/ChartEditor/GuitarSetup/GuitarSetup";
import FretboardNotesSelect from "./FretboardNotesSelect";
import classes from "./FretboardSetup.module.css";
import { mainActions } from "../../../store";
import FretboardStartTraining from "./FretboardStartTraining";
import FretboardScaleSelect from "./FretboardScaleSelect";
import FretboardSharpsOrFlats from "./FretboardSharpsOrFlats";
import FretboardShowNoteNames from "./FretboardShowNoteNames";

const FretboardSetup = ({ isTraining, onTraining, showNoteNames, onShowNoteNames}) => {
  const fretboardNotesDisplay = useSelector(
    (state) => state.fretboardNotesDisplay
  );
  const dispatch = useDispatch();

  const onUpdateFretboardDisplay = (index, checked) => {
    dispatch(
      mainActions.setFretboardNotesDisplay({ index: index, value: checked })
    );
  };

  return (
    <table className={classes.tableLayout}>
      <tbody>
        <GuitarSetup />
        <FretboardScaleSelect />
        <tr>
          <td></td>
          <td>C</td>
          <td></td>
          <td>D</td>
          <td></td>
          <td>E</td>
          <td>F</td>
          <td></td>
          <td>G</td>
          <td></td>
          <td>A</td>
          <td></td>
          <td>B</td>
        </tr>
        <FretboardNotesSelect
          title="Notes"
          values={fretboardNotesDisplay}
          onUpdate={onUpdateFretboardDisplay}
        />
        <FretboardSharpsOrFlats />
        <FretboardShowNoteNames state={showNoteNames} onClick={onShowNoteNames} />
        <FretboardStartTraining state={isTraining} onClick={onTraining} />
      </tbody>
    </table>
  );
};

export default FretboardSetup;
