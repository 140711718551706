import { useSelector } from "react-redux";
import classes from "./PublishScalePage.module.css";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SingleChartRenderer = ({ index, image, width, height, onClick }) => {
  const src = `data:image/svg+xml;utf8,${encodeURIComponent(image)}`;

  return (
    <div id={`${index}`} style={{ textAlign: "left" }}>
      <img
        onClick={onClick}
        className={classes.chartimg}
        src={src}
        alt="Guitar Chart data"
        style={{
          objectFit: "cover",
          width: width,
          height: height,
        }}
      />
    </div>
  );
};

const PublishScalePage = (props) => {
  const location = useLocation();
  const publishedData = useSelector((state) => state.publishedData);

  const title = useSelector((state) => state.title);
  const introduction = useSelector((state) => state.introduction);
  const imageSize = useSelector((state) => state.imageScale);

  let maxImageWidth = 0;
  const elements = publishedData.map((perChart, index) => {
    const width = (imageSize * perChart.width) / 100.0;
    const height = (imageSize * perChart.height) / 100.0;

    if (width > maxImageWidth) {
      maxImageWidth = width;
    }
    return (
      <SingleChartRenderer
        key={`${index}`} 
        index={index}
        image={perChart.image}
        width={width}
        height={height}
        onClick={props.onClick}
      />
    );
  });
  maxImageWidth += 0;

  console.log(
    `{'type': 'link', 'name': '${title}', 'queryString':'${location.search.substring(
      1
    )}'},`
  );
  return (
    <div className={classes.background}>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>
            {title}
          </title>
          <meta
            name="description"
            content="Guitar Charts Designer is a web app that lets you create and print custom guitar charts and diagrams."
          />
        </Helmet>
      </HelmetProvider>
      <div className={classes["a4-paper"]}>
        <div className={classes["content"]}>
          <div className={classes["link"]} onClick={props.onClick}>
            Created with guitar-charts.com
          </div>
          <h1>{title}</h1>
          <div>
            <ReactMarkdown>{introduction}</ReactMarkdown>
          </div>
          <div
            className={classes.imageGrid}
            style={{
              gridTemplateColumns: `repeat(auto-fit, minmax(${maxImageWidth}px, 1fr))`,
            }}
          >
            {elements}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublishScalePage;
