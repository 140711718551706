import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../store";
import classes from "./EarTrainerSetup.module.css";

const EarTrainerShowNotesSelect = () => {
  const tinyScreen = useSelector((state) => state.tinyScreen);
  const dispatch = useDispatch();

  const earTrainerShowNotes = useSelector((state) => state.earTrainerShowNotes);

  const showNotesChangeHandler = (event) => {
    dispatch(mainActions.setEarTrainerShowNotes(+event.target.value));
  };

  const tinyOptions = (
    <>
      <option value="0">All</option>
      <option value="1">Roots</option>
      <option value="2">Low</option>
      <option value="3">High</option>
    </>
  );

  const normalOptions = (
    <>
      <option value="0">Show All Notes</option>
      <option value="1">Only Roots</option>
      <option value="2">Only Low Root</option>
      <option value="3">Only High Root</option>
    </>
  );

  return (
    <tr>
      <td className={classes.td}>
        <select
          value={earTrainerShowNotes}
          onChange={showNotesChangeHandler}
          className={classes.select}
        >
            {tinyScreen ? tinyOptions : normalOptions}
        </select>
      </td>
      <td>What to display on the charts?</td>
    </tr>
  );
};

export default EarTrainerShowNotesSelect;
