import Button from "../../UI/Button";
import classes from "./FretboardStartTraining.module.css";

const FretboardShowNoteNames = ({state,onClick}) => {
  const startTrainingText = "Show Notes";
  const stopTrainingText = "Hide Notes";

  return (
    <tr>
      <td colSpan="13">
        <Button onClick={onClick} className={classes.button}>
          {state ? stopTrainingText : startTrainingText}
        </Button>
      </td>
    </tr>
  );
};

export default FretboardShowNoteNames;
