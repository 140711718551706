import { useSelector } from "react-redux";
import BottomMenu from "../BottomMenu/BottomMenu";
import Card from "../UI/Card";
import { ChartDataToFretboardSVG } from "../code/ChartDataToSVG";
import classes from "./FretboardPage.module.css";
import NotePlayer from "../EarTrainerPage/NotePlayer";
import { useCallback, useEffect, useRef, useState } from "react";
import FretboardSetup from "./FretboardSetup/FretboardSetup";
import FretboardChart from "./FretboardChart";
import RequestSupportCard from "../UI/RequestSupportCard";
import { Helmet, HelmetProvider } from "react-helmet-async";

const midiPitchNameSharps = [
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
  "A",
  "A#",
  "B",
];
const midiPitchNameFlats = [
  "C",
  "D\u266D",
  "D",
  "E\u266D",
  "E",
  "F",
  "G\u266D",
  "G",
  "A\u266D",
  "A",
  "B\u266D",
  "B",
];

const createQuestions = (notes, useSharps, lastPitch = null) => {
  let questions = [];
  for (let n = 0; n < 12; n++) {
    const pitch = n;

    if (notes[pitch]) {
      if (useSharps === 1 || useSharps === 2) {
        questions.push([n, midiPitchNameSharps[pitch]]);
      }
      if (useSharps === 0 || useSharps === 2) {
        questions.push([n, midiPitchNameFlats[pitch]]);
      }
    }
  }

  for (let i = questions.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [questions[i], questions[j]] = [questions[j], questions[i]];
  }

  // if we've just asked this question, shove it to the end
  if (questions.length && lastPitch === questions[0][0]) {
    const i = 0;
    const j = questions.length;
    [questions[i], questions[j]] = [questions[j], questions[i]];
  }
  return questions;
};

const FretboardPage = () => {
  const fretboardNotesDisplay = useSelector(
    (state) => state.fretboardNotesDisplay
  );
  const [playPitch, setPlayPitch] = useState(false);
  const timeoutIdRef = useRef(null);
  const [pageState, setPageState] = useState("create charts");
  const [lastState, setLastState] = useState("none");
  const tuning = useSelector((state) => state.tuning);
  const midiRootNote = useSelector((state) => state.midiRootNote);
  const lowestFret = 0;
  const useSharps = useSelector((state) => state.fretboardUseSharps);
  const [maxFret, setMaxFret] = useState();
  const [isTraining, setIsTraining] = useState(false);

  const [showNoteNames, setShowNames] = useState(true);
  const [svgData, setSvgData] = useState({ image: null });
  const [questionList, setQuestionList] = useState();
  const [questionText, setQuestionText] = useState();
  const [questionMidi, setQuestionMidi] = useState();

  /***********************************************
   * play() Function Definition
   */
  const play = useCallback(
    (pitch) => {
      // Clear the previous timeout, if there was one
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      // Set the new pitch and create a timeout to clear it
      setPlayPitch(pitch);
      timeoutIdRef.current = setTimeout(() => {
        setPlayPitch(0);
        timeoutIdRef.current = null; // Clear the reference when the timeout is done
      }, 500);
    },
    [] // No dependencies needed
  );

  const handleNoteClick = (string, fret, shift) => {
    // calculate the pitch from the string, fret and tuning
    let pitch = lowestFret + midiRootNote + fret;
    for (let n = 0; n < string; n++) {
      pitch += tuning[n];
    }
    if (!isTraining) {
      play(pitch);
    } else if (questionMidi % 12 === pitch % 12) {
      play(pitch);
      setPageState("ask question");
      setLastState("");

      setQuestionList((last) => {
        if (last.length > 1) {
          return last.slice(1);
        } else {
          return createQuestions(
            fretboardNotesDisplay,
            useSharps,
            questionMidi
          );
        }
      });
    }
  };

  useEffect(() => {
    setLastState("");
    setPageState("create charts");
  }, [fretboardNotesDisplay, isTraining]);

  useEffect(() => {
    setSvgData(
      ChartDataToFretboardSVG(
        tuning,
        midiRootNote,
        lowestFret,
        maxFret,
        fretboardNotesDisplay,
        showNoteNames,
        useSharps
      )
    );
  }, [
    tuning,
    midiRootNote,
    lowestFret,
    maxFret,
    fretboardNotesDisplay,
    showNoteNames,
    useSharps,
  ]);

  useEffect(() => {
    if (pageState === "create charts" && pageState !== lastState) {
      setLastState(pageState);
      if (isTraining) {
        setPageState("ask question");
      }
      const maxfret = Math.max(...tuning) + 12;
      setMaxFret(maxfret);
    }
  }, [
    pageState,
    lastState,
    tuning,
    fretboardNotesDisplay,
    maxFret,
    midiRootNote,
    showNoteNames,
    isTraining,
    lowestFret,
    useSharps,
  ]);

  useEffect(() => {
    setQuestionList(createQuestions(fretboardNotesDisplay, useSharps));
  }, [fretboardNotesDisplay, useSharps]);

  useEffect(() => {
    if (
      pageState === "ask question" &&
      lastState !== pageState &&
      questionList &&
      questionList[0]
    ) {
      setLastState(pageState);
      console.log(questionList);
      const randomElement = questionList[0];
      setQuestionText(randomElement[1]);
      setQuestionMidi(randomElement[0]);
    }
  }, [pageState, lastState, questionList]);

  const onTraining = () => {
    if (questionList.length) {
      setIsTraining((last) => !last);
    } else {
      setIsTraining(false);
    }
  };

  const onShowNoteNames = () => {
    setShowNames((last) => !last);
  };

  return (
    <div className={classes.background}>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Guitar Charts - Fretboard Trainer</title>
          <meta
            name="description"
            content="Guitar Charts Designer is a web app that lets you create and print custom guitar charts and diagrams."
          />
        </Helmet>
      </HelmetProvider>
      <Card>
        <h1>Guitar Charts - Fretboard Trainer</h1>
      </Card>
      <RequestSupportCard />
      <Card>
        <FretboardSetup
          isTraining={isTraining}
          onTraining={onTraining}
          showNoteNames={showNoteNames}
          onShowNoteNames={onShowNoteNames}
        />
      </Card>
      <Card>
        <table>
          <tbody>
            <tr>
              <td style={{ textAlign: "center" }}>
                Click on the chart below to play the notes
              </td>
            </tr>
            <tr>
              <td className={classes.td}>
                {questionList && !questionList.length ? (
                  <b>Add Some Notes!</b>
                ) : (
                  isTraining && (
                    <>
                      Can you find a&nbsp;<b>{questionText}</b>?
                    </>
                  )
                )}
                &nbsp;
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
      <Card>
        <div className={classes.imageGrid}>
          <FretboardChart
            image={svgData.image}
            onClick={handleNoteClick}
            stringCount={tuning.length + 1}
            maxFret={maxFret}
          />
        </div>
      </Card>
      <Card>
        <div className={classes.articleDiv}>
          <h1>
            Mastering the Guitar Neck: <br />A Comprehensive Guide to <br />
            Learning All the Notes
          </h1>
          <h2>Stage 1 - The Open Strings:</h2>
          <p>
            Typically, you would leave the <b>"Guitar Setup"</b> as{" "}
            <b>"Standard 6 String Guitar eg. EADGBE"</b>, as that is the
            standard guitar layout. However, you can use this method for any
            instrument or tuning.
          </p>
          <p>
            For the <b>"Scale,"</b> choose <b>"Open Strings"</b>. If the notes
            are being shown, you should see circles to the left of the fret
            markings highlighted with the names of the open strings.
          </p>
          <p>
            My favorite mnemonic when I was first learning was, "
            <i>
              <b>E</b>very <b>A</b>pple <b>D</b>oes <b>G</b>o <b>B</b>ad{" "}
              <b>E</b>ventually
            </i>
            ," which gives you the letters of the notes from the lowest string
            to the highest. There are many other mnemonics you can use as well.
          </p>
          <p>
            Now start training by clicking on <b>"Start Training!"</b> and don't
            forget to hide the notes by clicking on <b>"Hide Notes."</b>
          </p>
          <p>
            Just focus on the gray circles beside each open string and see if
            you can find the notes asked for just above the chart.
          </p>
          <p>
            When you get a question right, you'll hear the note and a new
            question will be asked. When you get a question wrong, you won't
            hear anything, and you can try again.
          </p>
          <p>
            Give yourself a little time to acclimate and start finding all the
            notes more readily.
          </p>
          <h2>Stage 2 - The E String</h2>
          <p>
            Set the <b>"Scale"</b> to be <b>"C Major/ A Minor"</b> and show all
            the notes by clicking <b>"Show Notes"</b> if they're not already
            visible.
          </p>
          <p>
            Notice that the note <b>E</b> first occurs on the open string of the
            E String and then occurs again at the 12th fret. This is because all
            of the notes are available at some point between the open fret and
            the 12th fret, and this is true for all the other strings too.
          </p>
          <p>
            Let's just focus on that low E string and see if we can find them.
          </p>
          <p>
            Again, hide the notes and start training if you haven't already. See
            if you can find all the notes as the questions arrive.
          </p>
          <p>
            If this feels too hard, you can always take a quick peek at where
            the notes are or even simplify things by removing some of the notes.
          </p>
          <p>
            As you get better, try adding some more notes until you can do the
            full Chromatic Scale.
          </p>
          <p>
            Don't worry if this takes a few sessions before it clicks. That's
            totally normal.
          </p>
          <p>
            You may find the sharps and flats particularly challenging. If so,
            try a few sessions focused on these. You can use the Scale{" "}
            <b>"Sharps or Flats"</b> to set things up quickly.
          </p>
          <h2>Stage 3 - The Other Strings</h2>
          <p>
            The guitar is tuned with 2 E strings. Not surprisingly, all the
            notes on the high E string are in an identical place to the low E
            string.
          </p>
          <p>
            It may take your brain a while to adjust, but ultimately, you should
            be able to find all the notes on the high E as easily as you can on
            the low E.
          </p>
          <p>
            So the next real challenge is the A string. The approach to the A
            string is the same as for the E string. Give yourself a little time,
            and pretty soon you'll get it too.
          </p>
          <p>
            Once you have that, move on to the others, or alternatively try{" "}
            <b>Stage 4</b> and come back later for the other strings.
          </p>
          <h2>Stage 4 - The Root Position</h2>
          <p>
            When we talk about the <b>root position</b>, we mean finding and
            playing all the notes from the open strings to the 4th fret.
          </p>
          <p>
            There's a useful property of the guitar, which is that all the notes
            of the chromatic scale occur at least once across a span of 5 frets
            and across a group of 3 strings. We're going to take advantage of
            that for the next part of the training.
          </p>
          <p>
            Let's start with the <b>high E, B, and G strings</b> and just focus
            on the <b>open strings up to the 4th fret</b>.
          </p>
          <p>
            Again, you can simplify the scale if you want, but the goal is to
            build up to where you can find all of the notes of the Chromatic
            scale.
          </p>
          <p>
            Similarly, we can do the same thing with the bottom 3 strings, the{" "}
            <b>low E, A, and D strings</b>.
          </p>
          <p>
            Actually, you can take this same approach with any 3 consecutive
            strings, so why not test it out too? For example, try the{" "}
            <b>D, G and B strings</b> or the <b>A, D and G strings</b>, all from
            the <b>open strings up to the 4th fret</b>
          </p>
          <h2>Stage 5 - The 12th Fret</h2>
          <p>
            Another interesting property of the guitar is that everything
            repeats above the 12th fret. So all of the notes that you found in
            the root position occur again from the 12th fret. Click{" "}
            <b>Show Notes</b>, and you should be able to see what I mean.
          </p>
          <p>
            Again, we're going to train in groups of 3 strings over a 5-fret
            span, so you are only able to play the notes from the <b>12th to the
            16th frets</b>.
          </p>
          <p>
            Once you get the hang of it, you should find these start to come
            easily.
          </p>
          <h2>Stage 6 - The Other Frets</h2>
          <p>
            Now you're at the stage where you can test yourself on other fret
            ranges.
          </p>
          <p>
            Again, pick a set of 3 strings and a start and end fret. It doesn't
            really matter which ones, but if I were to recommend anything, I'd
            say:
          </p>
          <ul>
            <li>5th Fret to 9th Fret</li>
            <li>9th Fret to the 13th Fret</li>
            <li>3rd Fret to the 7th Fret</li>
          </ul>
          <p>
            At this point, you have all the basic knowledge you need, and from
            now on, you're just improving your fluency.
          </p>
          <h2>Final Thoughts</h2>
          <p>
            This kind of training is best done using lots of short sessions
            spread out over the weeks and months, rather than trying to cram
            everything into one huge session.
          </p>
          <p>
            Give yourself the time you need to achieve it, and you may be amazed
            at how quickly you can pick it up!
          </p>
          <p>
            <b>
              <i>Good Luck! - Steve</i>
            </b>
          </p>{" "}
        </div>
      </Card>
      <Card>
        <BottomMenu />
      </Card>
      <NotePlayer pitch={playPitch} />
      {/* put some space in the bottom of the chart so you can scroll the ear trainer chart into view */}
      <div style={{ height: "100vh" }} />
    </div>
  );
};

export default FretboardPage;
