  

const GenerateExtendedScaleNotes = (scaleData, scaleColor) => {
    let scale = [];
    for (let n = 0; n < 12; n++) {
      if (scaleData[n]) {
        scale.push(scaleColor[n]);
      } else {
        scale.push("");
      }
    }
  
    scale = [...scale, ...scale, ...scale, ...scale, ...scale];
    
    return scale;
}

export default GenerateExtendedScaleNotes;
