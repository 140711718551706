
import classes from './FretboardNotesSelect.module.css';

const FretboardNotesSelect = ({title, values, onUpdate }) => {
    
    const indexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    const onUpdateHandler = (event) => {
        console.log("onUpdateHandler");
         console.log(event);
         onUpdate(+event.target.id,event.target.checked);
      };
    
      const boxes = indexes.map((index) => {
        return (
          <td key={`${index}`}>
            <input
              className={classes.input}
              checked={values[index]}
              id={`${index}`}
              type="checkbox"
              onChange={onUpdateHandler}
            />
          </td>
        );
      });
    
      return (
        <tr>
          <td>{title}</td>
          {boxes}
        </tr>
      );
};

export default FretboardNotesSelect;