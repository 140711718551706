import BottomMenu from "../BottomMenu/BottomMenu";
import classes from "./SupportMePage.module.css";
import Card from "../UI/Card";
import { Helmet, HelmetProvider } from "react-helmet-async";

const SupportMePage = () => {
  const bodyText = (
    <div className={classes.content}>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>
            Support Me
          </title>
          <meta
            name="description"
            content="Guitar Charts Designer is a web app that lets you create and print custom guitar charts and diagrams."
          />
        </Helmet>
      </HelmetProvider>
      <h1>Support Me</h1>
      <p>
        Thank you for visiting my website for generating Guitar Charts! As a
        passionate guitarist myself, I know how important it is to have access
        to high-quality resources for practicing and improving our skills.
      </p>
      <p>
        I created this website with the goal of making it easier for guitar
        players of all levels to generate accurate and customizable scale
        charts, and then learn how to use them. Whether you're a beginner who's
        just getting started with scales or an advanced player who wants to
        explore new patterns and modes, my website has something for you.
      </p>
      <p>
        However, developing and maintaining a website like this takes a lot of
        time and effort. That's why I'm asking for your support to help me
        continue providing this service to the guitar community.
      </p>
      <p>
        If you find my website useful and want to support its ongoing
        development, there are several ways you can help:
      </p>
      <ol>
        <li className={classes.li}>
          <b>Spread the word:</b> Share my website with your friends and fellow
          guitar players. The more people who know about it, the better!
        </li>
        <li className={classes.li}>
          <b>Donate:</b> If you're able to, consider making a donation to help
          cover the costs of running the website and developing new features.
        </li>
        <li className={classes.li}>
          <b>Provide feedback:</b> I'm always looking for ways to improve the
          website and make it more user-friendly. If you have any suggestions or
          feedback, please don't hesitate to contact me at jambole0192 @
          gmail.com
        </li>
      </ol>
      <p>Thank you for your support, and happy practicing!</p>
      <div className={classes.lowerButtonContainer}>
        <a href="https://www.patreon.com/SixStringsAttachedTV">
          <img
            src="become_a_patron_button.png"
            alt="Support me on Patreon"
            width="200"
            height="60"
          />
        </a>
        <a
          href="https://www.buymeacoffee.com/jambole019X"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            alt="Buy Me A Coffee"
            style={{ height: "60px", width: "217px" }}
          />
        </a>
      </div>
    </div>
  );

  return (
    <div className={classes.background}>
      <Card>{bodyText}</Card>
      <Card>
        <BottomMenu />
      </Card>
    </div>
  );
};

export default SupportMePage;
