import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./FretboardScaleSelect.module.css";
import { mainActions } from "../../../store";


const scales = {
  "[t,t,t,t,t,t,t,t,t,t,t,t]": "Chromatic",
  "": "Open Strings",
  "[f,t,f,t,f,f,t,f,t,f,t,f]": "Sharps or Flats",
  "[t,f,t,f,t,t,f,t,f,t,f,t]": "C Major/ A Minor",
  "[f,t,f,t,t,f,t,f,t,f,t,t]": "B Major/ G# Minor",
  "[t,f,t,t,f,t,f,t,f,t,t,f]": "B\u266d Major/ G Minor",
  "[f,t,t,f,t,f,t,f,t,t,f,t]": "A Major/ F# Minor",
  "[t,t,f,t,f,t,f,t,t,f,t,f]": "A\u266d Major/ F Minor",
  "[t,f,t,f,t,f,t,t,f,t,f,t]": "G Major/ E Minor",
  "[f,t,f,t,f,t,t,f,t,f,t,t]": "G\u266d Major/ E\u266d Minor",
  "[t,f,t,f,t,t,f,t,f,t,t,f]": "F Major/ D Minor",
  "[f,t,f,t,t,f,t,f,t,t,f,t]": "E Major/ C# Minor",
  "[t,f,t,t,f,t,f,t,t,f,t,f]": "E\u266d Major/ C Minor",
  "[f,t,t,f,t,f,t,t,f,t,f,t]": "D Major/ B Minor",
  "[t,t,f,t,f,t,t,f,t,f,t,f]": "D\u266d Major/ B\u266d Minor",
};

const FretboardScaleSelect = () => {
  const fretboardNotesDisplay = useSelector(
    (state) => state.fretboardNotesDisplay
  );
  const dispatch = useDispatch(); 
  const guitarSetup = useSelector((state) => state.guitarSetup);

  const tuning = useSelector((state) => state.tuning);
  const midiRootNote = useSelector((state) => state.midiRootNote);
  const [openStrings, setOpenStrings] = useState("");
  const [guitarScale, setGuitarScale] = useState();
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    let notes = Array(12).fill(false);
    let note = midiRootNote;
    notes[note % 12] = true;
    for(let t=0;t<tuning.length;t++) {
        note += tuning[t];
        notes[note%12] = true;
    }
    const str = JSON.stringify(notes)
    .replace(/true/g, "t")
    .replace(/false/g, "f");

    setOpenStrings(str);
  }, [tuning, midiRootNote, guitarSetup]);

  useEffect(() => {
    const str = JSON.stringify(fretboardNotesDisplay)
      .replace(/true/g, "t")
      .replace(/false/g, "f");

      if (str === openStrings) {
        setIsValid(true);
        setGuitarScale("");
      } else {
        if (str in scales) {
            setIsValid(true);
            setGuitarScale(str);
        } else {
            setIsValid(false);
        }
      }
  }, [fretboardNotesDisplay,openStrings]);

  const options = Object.keys(scales).map((item, index) => {
    return (
      <option key={index} value={item}>
        {scales[item]}
      </option>
    );
  });

  const selectOnChangeHandler = (event) => {
    setGuitarScale(event.target.value);

    const value = event.target.value === "" ? openStrings : event.target.value;

    const json = value.replace(/t/g, "true").replace(/f/g, "false");

    const newIntervals = JSON.parse(json);
    dispatch(mainActions.setAllFretboardNotesDisplay(newIntervals));
  };

  return (
    <tr>
      <td>Scale</td>
      <td colSpan={12}>
        <select className={isValid ? classes.selectValid : classes.selectNotValid}
            value={guitarScale} onChange={selectOnChangeHandler}>{options}</select>
      </td>
    </tr>
  );
};

export default FretboardScaleSelect;
