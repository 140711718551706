import { useSelector } from "react-redux";
import Card from "./Card";

const RequestSupportCard = () => {
  const tinyScreen = useSelector((state) => state.tinyScreen);

  const thankyou = <p>Thank you for your support, and happy practicing!</p>;
  const patreon = (
    <a href="https://www.patreon.com/SixStringsAttachedTV">
      <img
        src="become_a_patron_button.png"
        alt="Support me on Patreon"
        width="200"
        height="60"
      />
    </a>
  );
  const coffee = (
    <a
      href="https://www.buymeacoffee.com/jambole019X"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
        alt="Buy Me A Coffee"
        style={{ height: "60px", width: "217px" }}
      />
    </a>
  );

  const normalLayout = (
    <Card>
      <table>
        <tbody>
          <tr>
            <td colSpan={2} style={{ textAlign: "center" }}>
              {thankyou}
            </td>
          </tr>
          <tr>
            <td>{patreon}</td>
            <td> {coffee}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );

  const tinyLayout = (
    <Card>
      <table>
        <tbody>
          <tr>
            <td style={{ textAlign: "center" }}>{thankyou}</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>{patreon}</td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>{coffee}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );

  return tinyScreen ? tinyLayout : normalLayout;
};

export default RequestSupportCard;
