import BlogDisplayArticle from "./BlogDisplayArticle";
import blogInfo from "./BlogInfo";

const BlogPage = () => {
  const location = window.location.pathname.substring(1);

  if (location in blogInfo) {
    return <BlogDisplayArticle url={`${blogInfo[location]}/article`} />;
  } else {
    return <BlogDisplayArticle url="/blogdata/contents" />;
  }
};

export default BlogPage;
