import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../store";
import classes from "./EarTrainerSetup.module.css";

const EarTrainerRootSelect = () => {
  const tinyScreen = useSelector((state) => state.tinyScreen);
  const dispatch = useDispatch();
  const earTrainerRootNote = useSelector((state) => state.earTrainerRootNote);

  const rootNoteChangeHandler = (event) => {
    dispatch(mainActions.setEarTrainerRootNote(+event.target.value));
  };

  const rn = tinyScreen ? "" : " Root Note";

  return (
    <tr>
      <td className={classes.td}>
        <select
          className={classes.select}
          value={earTrainerRootNote}
          onChange={rootNoteChangeHandler}
        >
          <option value="45">A{rn}</option>
          <option value="46">A#/ B&#9837;{rn}</option>
          <option value="47">B{rn}</option>
          <option value="48">C{rn}</option>
          <option value="49">C#/ D&#9837;{rn}</option>
          <option value="50">D{rn}</option>
          <option value="51">D# E&#9837;{rn}</option>
          <option value="40">E{rn}</option>
          <option value="41">F{rn}</option>
          <option value="42">F#/ G&#9837;{rn}</option>
          <option value="43">G{rn}</option>
          <option value="44">G#/ A&#9837;{rn}</option>
        </select>
      </td>
      <td>Choose your root note</td>
    </tr>
  );
};

export default EarTrainerRootSelect;
