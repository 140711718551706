import { HelmetProvider } from "react-helmet-async";
import Card from "../UI/Card";
import RequestSupportCard from "../UI/RequestSupportCard";
import classes from "./Metronome.module.css";
import { Helmet } from "react-helmet";
import Metronome from "./metronome/metronome";
import Button from "../UI/Button";
import { useRef, useState } from "react";
import BottomMenu from "../BottomMenu/BottomMenu";

const MetronomePage = () => {
  const [currentBpm, setCurrentBpm] = useState(120);
  const [editBpm, setEditBpm] = useState(false);
  const onPlayCallback = useRef(null);
  const onTempoChangeCallback = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRamping, setIsRamping] = useState(true);
  const [volume, setVolume] = useState(80);
  const minBpm = 10;
  const maxBpm = 400;

  const onMetromomeTick = () => {
    if (isRamping) {
      setCurrentBpm((bpm) => {
        //console.log(bpm);
        let newBpm = bpm + 0.02;
        if (newBpm > 400) {
          newBpm = 400;
        }
        onTempoChangeCallback.current(newBpm);
        return newBpm;
      });
    }
  };

  const renderCallback = ({
    tempo,
    beatsPerMeasure,
    playing,
    beat,
    subdivision,
    onPlay,
    onTempoChange,
  }) => {
    //console.log("renderCallback",playing);
    onPlayCallback.current = onPlay;
    onTempoChangeCallback.current = onTempoChange;

    return; /*(
      <div>
        <header>
          {tempo} <small>BPM</small>
          {beatsPerMeasure}/{beatsPerMeasure} <small>T.S.</small>
        </header>

        <main>
          <input
            type="range"
            min={minBpm}
            max={maxBpm}
            value={tempo}
            onChange={(event) => onTempoChange(event.target.value)}
          />
          {beat}/{beatsPerMeasure}
          <button onClick={onPlay}>{playing ? "Pause" : "Play"}</button>
        </main>
      </div>
    );*/
  };

  const onMetronomeStartCallback = () => {
    setIsPlaying(true);
  };
  const onMetronomeStopCallback = () => {
    setIsPlaying(false);
  };

  const absSetBpm = (bpm) => {
    let newBpm = bpm;
    if (newBpm < minBpm) {
      newBpm = minBpm;
    } else if (newBpm > maxBpm) {
      newBpm = maxBpm;
    }

    setCurrentBpm(newBpm);

    onTempoChangeCallback.current(newBpm);
  };

  const incSetBpm = (inc) => {
    setCurrentBpm((bpm) => {
      let newBpm = bpm + inc;
      if (newBpm < minBpm) {
        newBpm = minBpm;
      } else if (newBpm > maxBpm) {
        newBpm = maxBpm;
      }

      onTempoChangeCallback.current(newBpm);
      return newBpm;
    });
  };

  const onBpmKeyDownEvent = (event) => {
    console.log("onBpmKeyDownEvent", event.keyCode);

    if (event.keyCode === 13) {
      absSetBpm(+event.target.value);
      setEditBpm(false);
    } else if (event.keyCode === 27) {
      setEditBpm(false);
    }
  };

  const absBtn = (inc) => {
    return (
      <Button
        className={classes.mbutton}
        onClick={() => {
          absSetBpm(+inc);
        }}
      >
        {`to ${inc}bpm`}
      </Button>
    );
  };

  const trimBtn = (inc) => {
    return (
      <Button
        className={classes.mbutton}
        onClick={() => {
          incSetBpm(+inc);
        }}
      >
        {inc >= 0 ? `+${inc}` : `${inc}`}
      </Button>
    );
  };

  return (
    <div className={classes.background}>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Guitar Charts - Ramping Metronome</title>
          <meta
            name="description"
            content="Ramping Metronome is a Metronome that allows you to gradually increase the pace of the metronome over time."
          />
        </Helmet>
      </HelmetProvider>

      <Card>
        <h1 className={classes.h1}>Guitar Charts - Ramping Metronome</h1>
      </Card>
      <RequestSupportCard />
      <Card className={classes.container}>
        <table className={classes.mtable}>
          <tbody>
            <tr>
              <td>{absBtn(40)}</td>
              <td>{absBtn(60)}</td>
            </tr>
            <tr>
              <td>{absBtn(80)}</td>
              <td>{absBtn(100)}</td>
            </tr>
            <tr>
              <td>{absBtn(120)}</td>
              <td>{absBtn(140)}</td>
            </tr>
            <tr>
              <td>{absBtn(160)}</td>
              <td>{absBtn(180)}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <table className={classes.mtable}>
          <tbody>
            <tr>
              <td>
                <Button
                  className={classes.mbutton}
                  onClick={() => {
                    setIsRamping((state) => !state);
                  }}
                >
                  {isRamping
                    ? "Ramping Metronome Enabled"
                    : "Ramping Metronome Disabled"}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <br />

        <table className={classes.mtable}>
          <tbody>
            <tr>
              <td>
                {editBpm ? (
                  <input
                    className={classes.mbutton}
                    type="number"
                    min={0}
                    max={1000}
                    defaultValue={Math.round(currentBpm)}
                    onKeyDown={onBpmKeyDownEvent}
                  ></input>
                ) : (
                  <Button
                    className={classes.bpmButton}
                    onClick={() => {
                      setEditBpm(true);
                    }}
                  >
                    <b>{Math.round(currentBpm)}bpm</b>
                  </Button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <table className={classes.mtable}>
          <tbody>
            <tr>
              <td>
                <input
                  className={classes.mslider}
                  type="range"
                  min={0}
                  max={100}
                  value={volume}
                  onChange={(event) => setVolume(event.target.value)}
                />
              </td>
              <td><div style={{ textAlign: 'right' }}>{volume}%</div></td>
            </tr>
          </tbody>
        </table>
        <br />
        <table className={classes.mtable}>
          <tbody>
            <tr>
              <td>{trimBtn(-5)}</td>
              <td>{trimBtn(-1)}</td>
              <td>{trimBtn(1)}</td>
              <td>{trimBtn(5)}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <table className={classes.mtable}>
          <tbody>
            <tr>
              <td>
                <Button
                  className={classes.mbutton}
                  onClick={onPlayCallback.current}
                >
                  {isPlaying ? "Stop" : "Start"}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <Metronome
          tempo={currentBpm}
          beatsPerMeasure={1}
          render={renderCallback}
          onStart={onMetronomeStartCallback}
          onStop={onMetronomeStopCallback}
          onTick={onMetromomeTick}
          beatVolume={volume / 100.0}
        />
        <br />
      </Card>
      <Card>
        <BottomMenu />
      </Card>

      {/* put some space in the bottom of the chart so you can scroll the ear trainer chart into view */}
      <div style={{ height: "100vh" }} />
    </div>
  );
};

export default MetronomePage;
