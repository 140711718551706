import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/index";
import { RouterProvider, createBrowserRouter } from "react-router-dom";


// remember to modify sitemap.xml if you add more pages.
// /library/* will accept any subpath of library
const router = createBrowserRouter([
  { path: "/", element: <App page={"home"}/> },
  { path: "/library/*", element: <App page={"library"}/> },
  { path: "/support", element: <App page={"support"}/> },
  { path: "/edit", element: <App page={"home"}/> }, /* was edit  so keeping for legacy */
  { path: "/publish", element: <App page={"publish"}/> },
  { path: "/privacy", element: <App page={"privacy"}/> },
  { path: "/terms", element: <App page={"terms"}/> },
  { path: "/training", element: <App page={"training"}/> },
  { path: "/fretboard", element: <App page={"fretboard"}/> },
  { path: "/metronome", element: <App page={"metronome"}/> },
  { path: "/blog/*", element: <App page={"blog"}/> },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
