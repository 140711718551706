import { useEffect, useState } from "react";
import Card from "../UI/Card";
import classes from "./BlogArticle.module.css";
import BottomMenu from "../BottomMenu/BottomMenu";
import {  Helmet, HelmetProvider } from "react-helmet-async";

const BlogDisplayArticle = ({ url }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [data, setData] = useState("");
  useEffect(() => {
    //console.log(url);
    fetch(url)
      .then((response) => response.text())
      .then((receivedData) => {
        //console.log(receivedData);
        const splitString = receivedData.split("\n#\n");
        setTitle(splitString[0]);
        setDescription(splitString[1]);
        setData(splitString[2]);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  }, [url]);

  return (
    <div className={classes.background}>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>
            {title}
          </title>
          <meta
            name="description"
            content={description}
          />
        </Helmet>
      </HelmetProvider>
      <Card>
        <div
          className={classes.div}
          dangerouslySetInnerHTML={{ __html: data }}
        />
      </Card>
      <Card>
        <BottomMenu />
      </Card>
      <div style={{ height: "800px" }} />
    </div>
  );
};

export default BlogDisplayArticle;
