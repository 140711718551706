import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../store";
import classes from "./FretboardSetup.module.css";

const FretboardSharpsOrFlats = () => {
  const useSharps = useSelector((state) => state.fretboardUseSharps);
  const dispatch = useDispatch();

  const selectOnChangeHandler = (event) => {
    dispatch(mainActions.setFretboardUseSharps(+event.target.value));
  };

  return (
    <tr>
      <td>Sharps/ Flats</td>
      <td colSpan={12}>
        <select
          className={classes.select}
          value={`${useSharps}`}
          onChange={selectOnChangeHandler}
        >
          <option value="0">Use Flats</option>
          <option value="1">Use Sharps</option>
          <option value="2">Use Both</option>
        </select>
      </td>
    </tr>
  );
};

export default FretboardSharpsOrFlats;
