import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../store";
import Button from "../../UI/Button";

import classes from './EarTrainerSetup.module.css';

const EarTrainerStartTrainingSelect = () => {
  const startTrainingText = "Start Training!";
  const stopTrainingText = "Stop Training";

  const dispatch = useDispatch();

  const earTrainerMode = useSelector((state) => state.earTrainerMode);

  const buttonOnClickHandler = () => {
    const newMode = earTrainerMode ? 0 : 1;
    dispatch(mainActions.setEarTrainerMode(newMode));
  };
  return (
    <tr>
      <td colSpan="2">
        <Button onClick={buttonOnClickHandler} className={classes.button}>
          {earTrainerMode ? stopTrainingText : startTrainingText}
        </Button>
      </td>
    </tr>
  );
};

export default EarTrainerStartTrainingSelect;
