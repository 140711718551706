import Button from "../../UI/Button";
import classes from "./FretboardStartTraining.module.css";

const FretboardStartTraining = ({state,onClick}) => {
  const startTrainingText = "Start Training!";
  const stopTrainingText = "Stop Training";

  return (
    <tr>
      <td colSpan="13">
        <Button onClick={onClick} className={classes.button}>
          {state ? stopTrainingText : startTrainingText}
        </Button>
      </td>
    </tr>
  );
};

export default FretboardStartTraining;
