import { useDispatch, useSelector } from "react-redux";
import classes from "./SelectNPS.module.css";
import { mainActions } from "../../../../store";

const SelectNPS = () => {
  const tinyScreen = useSelector((state) => state.tinyScreen);

  const selectNps = useSelector((state) => state.selectNps);
  const dispatch = useDispatch();

  const onChangeHandler = (event) => {
    dispatch(mainActions.setSelectNps(event.target.value));
  };

  return (
    <tr>
      <td className={classes.td}>{tinyScreen ? "Lay." : "Layout"}</td>
      <td colSpan={12} className={classes.td}>
        <select
          className={classes.select}
          value={selectNps}
          onChange={onChangeHandler}
        >
          <option value="auto">Automatically arrange notes</option>
          <option value="1nps">1 note per string</option>
          <option value="2nps">2 notes per string</option>
          <option value="3nps">3 notes per string</option>
          <option value="4nps">4 notes per string</option>
          <option value="3:1:3">Alternating 3:1:3</option>
          <option value="2:1:2">Alternating 2:1:2</option>
          <option value="2:1:1:1:1:2">Alternating 2:1:1:1:1:2</option>
          <option value="2:1:1:1:2">Alternating 2:1:1:1:2</option>
          <option value="3:2:3">Alternating 3:2:3</option>
        </select>
      </td>
    </tr>
  );
};

export default SelectNPS;
