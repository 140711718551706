import { useDispatch } from "react-redux";
import classes from "./RefineDialog.module.css";
import { mainActions } from "../../../store";
import Button from "../../UI/Button";
// props.src = the image source
// props.stringCount = the number of strings being drawn
// props.index = the chart index
// props.width = the image width.
// props.height the image height.
const RefineDialog = (props) => {
  const dispatch = useDispatch();

  const stringCount = props.stringCount;

  const deleteButtonHandler = () => {
    dispatch(mainActions.deleteRefineDataElement(props.index));
  };

  const duplicateButtonHandler = () => {
    dispatch(mainActions.duplicateRefineDataElement(props.index));
  };

  const incbutton = (event) => {
    let payload = {
      strIndex: stringCount - 1 - event.target.id,
      chartIndex: props.index,
      offset: 1,
    };
    dispatch(mainActions.trimRefineChartString(payload));
    payload.strIndex += 1;
    payload.offset = -1;
    dispatch(mainActions.trimRefineChartString(payload));
  };

  const decbutton = (event) => {
    const payload = {
      strIndex: stringCount - 1 - event.target.id,
      chartIndex: props.index,
      offset: -1,
    };
    dispatch(mainActions.trimRefineChartString(payload));
    payload.strIndex += 1;
    payload.offset = 1;
    dispatch(mainActions.trimRefineChartString(payload));
  };

  let incDecButtons = [];
  for (let n = 0; n < stringCount; n++) {
    incDecButtons.push(
      <div key={n} className={classes.btncontainer}>
        <button className={classes.decbutton} id={n} onClick={decbutton}>
          -
        </button>
        <button className={classes.incbutton} id={n} onClick={incbutton}>
          +
        </button>
      </div>
    );
  }

  return (
    <div key={props.index} className={classes.outerBox}>
      <div className={classes.innerBox}>
        <div className={classes.container}>
          <div className={classes.btnstackcontainer}>{incDecButtons}</div>
          <img
            className={classes.chartimg}
            src={props.src}
            alt="Guitar Chart data"
            style={{
              objectFit: "cover",
              width: props.width,
              height: props.height,
            }}
          />
        </div>
        <div className={classes.lowerButtonContainer}>
          <Button onClick={deleteButtonHandler}>Delete</Button>
          <Button onClick={duplicateButtonHandler}>Duplicate</Button>
        </div>
      </div>
    </div>
  );
};

export default RefineDialog;
