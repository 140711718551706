
import { mainActions } from "../../store";



// positions are an array of position structures
// position.scale = the scale data for the position
// position.chartData = the rendered scale as created by TextToChartData
const CreateRefineScaleData = (positions, dispatch) => {
  const perChartData = positions.map((position) => {
    const notesPerString = position.chartData.map((string) => string.length);
    return {
      notesPerString: notesPerString,
      notesOffset: position.notesOffset,
    };
  });

  const refineData = {
    perChartData: perChartData,
  };

  dispatch(mainActions.setRefineData(refineData));
};

export default CreateRefineScaleData;
