import GenerateExtendedScaleNotes from "./GenerateExtendedScaleNotes";
import GeneratePosition from "./GeneratePosition";

const GenerateBestFitPosition = (tuning, s) => {
  let strings = [[]];
  for (let n = 0; n < tuning.length; n++) {
    strings.push([]);
  }

  let lowestFret = 110;

  for (let n = 0; n < s.length; n++) {
    if (s[n] === "") {
      continue;
    }

    let bestStr = 0;
    let bestStrOffset = 1000;
    let pos = n;
    for (let f = 0; f < strings.length; f++) {
      if (pos >= 0 && pos < bestStrOffset) {
        bestStr = f;
        bestStrOffset = pos;
        if (f < tuning.length) {
          pos -= tuning[f];
        }
      }
    }

    if (bestStr === strings.length - 1 && pos - lowestFret > 4) {
      break;
    }

    if (bestStrOffset < lowestFret) {
      lowestFret = bestStrOffset;
    }

    strings[bestStr].push([bestStrOffset, s[n]]);
  }

  // now add an offset to normalise the lowest fret to 0
  let maxfrets = 0;
  for (let n = 0; n < strings.length; n++) {
    for (let f = 0; f < strings[n].length; f++) {
      const fret = strings[n][f][0] - lowestFret;
      strings[n][f][0] = fret;
      if (fret > maxfrets) {
        maxfrets = fret;
      }
    }
  }
  return { maxFret: maxfrets, chartData: strings, lowestFret: lowestFret };
};


const TextToChartDataNps = (
  tuning,
  scale,
  notesPerOctave,
  neckData,
  currentPositions
) => {
  let positions = [...currentPositions];
  for (let pos = 0; pos < notesPerOctave; pos++) {
    //if (scale[pos] === "0") {continue;}

    let s = scale.slice(pos);

    const position =
      neckData == null
        ? GenerateBestFitPosition(tuning, s)
        : GeneratePosition(tuning, neckData, s);

    // if we've not stored this object then
    let notContained = true;
    for (let n = 0; n < positions.length; n++) {
      if (
        JSON.stringify(position.chartData) ===
        JSON.stringify(positions[n].chartData)
      ) {
        notContained = false;
        break;
      }
    }
    if (notContained) {
      position.notesOffset = pos + position.lowestFret;
      positions.push(position);
    }
  }

  return positions;
};

const selectNpsLut = {
  auto: null,
  "1nps": [1],
  "2nps": [2],
  "3nps": [3],
  "4nps": [4],
  "3:1:3": [3, 1],
  "2:1:2": [2, 1],
  "2:1:1:1:1:2": [2, 1, 1, 1, 1],
  "2:1:1:1:2": [2, 1, 1, 1],
  "3:2:3": [3, 2],
};

const TextToChartData = (tuning, scaleData, scaleColor, selectNps) => {
  const scale = GenerateExtendedScaleNotes(scaleData, scaleColor);

  let layoutIterations = [];
  if (selectNps !== "auto") {
    const npsPattern = selectNpsLut[selectNps];
    const noOfStrings = tuning.length + 1;
    // create a layout of the npsPattern repeated mapped tuning+1 times.
    let layout = Array(noOfStrings)
      .fill(npsPattern)
      .flatMap((x) => x);

    npsPattern.map(() => {
      layoutIterations.push(layout.slice(0, noOfStrings));
      layout = layout.slice(1);
      return 0;
    });
  } else {
    layoutIterations = [null];
  }

  let currentPositions = [];
  layoutIterations.map((neckData) => {
    currentPositions = TextToChartDataNps(
      tuning,
      scale,
      scaleData.length,
      neckData,
      currentPositions
    );
    return 0;
  });
  return currentPositions;
};

/*
returns an array of positions, each one has the following
structure.
{
  maxFret=5,
  chartData=[
    [[0,"#ffffff"],[2,"#ffffff"],[4,"#ffffff"]],
    [[0,"#ffffff"],[2,"#ffffff"],[4,"#ffffff"]],
    [[0,"#ffffff"],[2,"#ffffff"],[4,"#ffffff"]],
    [[0,"#ffffff"],[2,"#ffffff"],[4,"#ffffff"]],
    [[0,"#ffffff"],[2,"#ffffff"],[4,"#ffffff"]],
    [[0,"#ffffff"],[2,"#ffffff"],[4,"#ffffff"]],]
    lowestFret=0,
    notesOffset=0
}
*/

export default TextToChartData;
