import classes from "./LibraryMenu.module.css";
import library from "./library";
import Button from "../UI/Button";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mainActions } from "../../store";
import UnpickleFromQueryString from "../../store/UnpickleFromQueryString";

const indent = 10;

const renderChildren = (
  padding,
  treeList,
  onFolderClick,
  onItemClick,
  expanded,
  activeId
) => {
  let retval = [];

  for (let n = 0; n < treeList.length; n++) {
    if ("children" in treeList[n]) {
      retval.push(
        <Button
          style={{ paddingLeft: `${padding}px` }}
          className={classes.folderButton}
          key={treeList[n].id}
          id={treeList[n].id}
          onClick={onFolderClick}
        >
          {treeList[n].name}
        </Button>
      );

      if (treeList[n].id in expanded && expanded[treeList[n].id]) {
        retval = retval.concat(
          renderChildren(
            padding + indent,
            treeList[n].children,
            onFolderClick,
            onItemClick,
            expanded,
            activeId
          )
        );
      }
    } else {
      retval.push(
        <a
          style={{ paddingLeft: `${padding}px` }}
          className={
            activeId === treeList[n].id
              ? classes.activeItemButton
              : classes.itemButton
          }
          href={`/${treeList[n].slug}`}
          key={treeList[n].id}
          id={treeList[n].id}
          onClick={onItemClick}
        >
          {treeList[n].name}
        </a>

        /*
        <Button
          style={{ paddingLeft: `${padding}px` }}
          className={
            activeId === treeList[n].id
              ? classes.activeItemButton
              : classes.itemButton
          }
          key={treeList[n].id}
          id={treeList[n].id}
          onClick={onItemClick}
        >
          {treeList[n].name}
        </Button>
        */
      );
    }
  }

  return retval;
};

const findIdFromSlug = (slug, liblist) => {
  for (let row = 0; row < liblist.length; row++) {
    if ("children" in liblist[row]) {
      let ret = findIdFromSlug(slug, liblist[row].children);

      if (ret !== null) return ret;
    } else {
      if (slug === liblist[row].slug) {
        return liblist[row].id;
      }
    }
  }
  return null;
};

const findRowFromId = (id, liblist) => {
  for (let row = 0; row < liblist.length; row++) {
    if ("children" in liblist[row]) {
      let ret = findRowFromId(id, liblist[row].children);

      if (ret !== null) return ret;
    } else {
      if (id === liblist[row].id) {
        return liblist[row];
      }
    }
  }
  return null;
};

const LibraryMenu = ({ onItemClicked }) => {
  const slug = window.location.pathname.substring(1);
  const id = findIdFromSlug(slug, library);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState({});

  const onFolderClick = (event) => {
    console.log("onFolderClick", event.target.id);

    setExpanded((oldList) => {
      const id = event.target.id;
      if (id in oldList) {
        return { ...oldList, [id]: !oldList[id] };
      } else {
        return { ...oldList, [id]: true };
      }
    });
  };

  const onItemClick = (event) => {
    event.preventDefault();
    const row = findRowFromId(event.target.id, library);
    console.log("onItemClick", row);
    if (row) {
      onItemClicked();

      UnpickleFromQueryString(row.queryString, dispatch);

      // store the last query string
      dispatch(mainActions.setQueryString(row.queryString));
      navigate("/" + row.slug);
    }
  };

  const items = renderChildren(
    indent,
    library,
    onFolderClick,
    onItemClick,
    expanded,
    id
  );
  return <div className={classes.menuDiv}>{items}</div>;
};

export default LibraryMenu;
