import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialState = {
  tinyScreen: false,
  title: "Put your chart name here...",
  introduction: "",
  imageScale: 75.0,

  isQueryStringUnpacked: false,

  intervals: [
    true,
    false,
    true,
    false,
    true,
    true,
    false,
    true,
    false,
    true,
    false,
    true,
  ],
  intervalColors: [
    "#000000",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
    "#ffffff",
  ],

  guitarSetup: "EADGBE",

  selectNps: "auto",

  // the tuning of the neck from low to high.
  tuning: [5, 5, 5, 4, 5],

  // root note of the tuning as a midi number
  midiRootNote: 40,

  refineData: {
    perChartData: [],
  },

  createdData: [],
  publishedData: [],

  queryString: "",
  menuSelected: 0,
  protectRefineData: true,

  // the index of the chart being used for ear training
  earTrainerChart: 0,

  // 0 = Text only, 1 = Text and Sounds, 2 = Sounds Only
  earTrainerQuestionType: 1,

  // 0 = ShowAllNotes
  // 1 = Show Only Roots
  // 2 = Show only low Root
  // 3 = Show only High Root
  earTrainerShowNotes: 0,

  // true, plays the chart, false, does Ear training
  // 0 means play the chart,
  // 1 means test mode
  earTrainerMode: 0,

  // 0 means never change, else the integer is the
  // number of notes before moving on.
  earTrainerShapeChange: 0,

  // the note of scale set.
  earTrainerRootNote: 40,

  // the note of scale set. 
  // 0 = lowest Note
  // 1 = highest Note
  earTrainerRootOctaveSelect: 0,

  // should I care about octaves in the answer
  earTrainerIgnoreOctaves: 0,

  // Fretboard specific data
  fretboardNotesDisplay: [
    true,
    false,
    true,
    false,
    true,
    true,
    false,
    true,
    false,
    true,
    false,
    true,
  ],

  fretboardUseSharps: 0,
};

const guitarSetups = {
  GCEA: [55, [5, 4, 5]],
  EADG: [28, [5, 5, 5]],
  BEADG: [23, [5, 5, 5, 5]],
  EADGBE: [40, [5, 5, 5, 4, 5]],
  EADGCF: [40, [5, 5, 5, 5, 5]],
  CGDAEG: [36, [7, 7, 7, 7, 3]],
  AbEbBbFCG: [32, [7, 7, 7, 7, 7]],
  BEADGBE: [35, [5, 5, 5, 5, 4, 5]],
  FsBEADGBE: [30, [5, 5, 5, 5, 5, 4, 5]],
  EBEADGBE: [28, [7, 5, 5, 5, 5, 4, 5]],
  DADGBE: [38, [7, 5, 5, 4, 5]],
  DADGBD: [38, [7, 5, 5, 4, 5]],
  DADGAD: [38, [7, 5, 5, 2, 7]],
  DADFsAD: [38, [7, 5, 4, 3, 5]], // open D
  DGDGBD: [38, [5, 7, 5, 4, 3]], // open G
  custom: [],
};

const selectNpsValues = [
  "auto",
  "3nps",
  "4nps",
  "2nps",
  "1nps",
  "3:1:3",
  "2:1:2",
  "2:1:1:1:1:2",
  "2:1:1:1:2",
  "3:2:3",
];

// create a slice of our global state
const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setTinyScreen(state, action) {
      const payload = action.payload;
      state.tinyScreen = payload;
    },
    setProtectRefineData(state, action) {
      const payload = action.payload;
      state.protectRefineData = payload;
    },

    setCreatedData(state, action) {
      const payload = action.payload;
      state.createdData = payload;
    },

    setPublishedData(state, action) {
      const payload = action.payload;
      state.publishedData = payload;
    },

    setTitle(state, action) {
      const payload = action.payload;
      state.title = payload;
    },

    setIntroduction(state, action) {
      const payload = action.payload;
      state.introduction = payload;
    },

    setImageScale(state, action) {
      const payload = +action.payload;
      state.imageScale = payload;
    },

    setInterval(state, action) {
      const payload = action.payload;
      state.intervals[payload.index] = payload.value;
    },
    setAllIntervals(state, action) {
      const payload = action.payload;
      state.intervals = payload;
    },
    setIntervalColor(state, action) {
      const payload = action.payload;
      state.intervalColors[payload.index] = payload.color;
    },
    setAllIntervalColors(state, action) {
      const payload = action.payload;
      state.intervalColors = payload;
    },
    setRefineData(state, action) {
      const payload = action.payload;
      state.refineData = payload;
      //console.log(payload);
    },

    deleteRefineDataElement(state, action) {
      const index = action.payload;
      state.refineData.perChartData.splice(index, 1);
    },

    duplicateRefineDataElement(state, action) {
      const index = action.payload;
      let dup = { ...state.refineData.perChartData[index] };
      state.refineData.perChartData.splice(index, 0, dup);
    },

    trimRefineChartString(state, action) {
      const strIndex = action.payload.strIndex;
      const chartIndex = action.payload.chartIndex;
      const offset = action.payload.offset;

      if (strIndex < 0 || chartIndex < 0) return;

      if (state.refineData.perChartData.length > chartIndex) {
        const chart = state.refineData.perChartData[chartIndex];
        if (chart.notesPerString.length > strIndex) {
          const notes = chart.notesPerString[strIndex] + offset;
          if (notes >= 0 && notes <= 8) {
            //state.refineData.perChartData[chartIndex].notesPerString[strIndex] = notes;
            chart.notesPerString[strIndex] = notes;
          }
        }
      }
    },

    setGuitarSetup(state, action) {
      const newValue = action.payload;
      if (newValue in guitarSetups) {
        state.guitarSetup = newValue;
        if (newValue === "custom") {
          return;
        }

        state.tuning = guitarSetups[newValue][1];
        state.midiRootNote = guitarSetups[newValue][0];
      }
    },
    setCustomTuning(state, action) {
      const newValue = action.payload;
      if (state.guitarSetup === "custom") {
        state.tuning = newValue;
      }
    },
    setMidiRootNote(state,action) {
      const newValue = +action.payload;
      if (state.guitarSetup === "custom") {
        state.midiRootNote = newValue;
      }
    },
    setSelectNps(state, action) {
      const newValue = action.payload;
      if (selectNpsValues.includes(newValue)) {
        state.selectNps = newValue;
      }
    },

    setQueryString(state, action) {
      const args = action.payload;
      state.queryString = args;
    },

    setMenuSelected(state, action) {
      const args = action.payload;
      state.menuSelected = args;
    },
    setIsQueryStringUnpacked(state, action) {
      const args = action.payload;
      state.isQueryStringUnpacked = args;
    },
    setEarTrainerChart(state, action) {
      const args = action.payload;
      state.earTrainerChart = args;
    },
    setEarTrainerQuestionType(state, action) {
      const args = action.payload;
      state.earTrainerQuestionType = args;
    },
    setEarTrainerShowNotes(state, action) {
      const args = action.payload;
      state.earTrainerShowNotes = args;
    },
    setEarTrainerMode(state, action) {
      const args = action.payload;
      state.earTrainerMode = args;
    },
    setEarTrainerShapeChange(state, action) {
      const args = action.payload;
      state.earTrainerShapeChange = args;
    },
    setEarTrainerRootNote(state, action) {
      const args = action.payload;
      state.earTrainerRootNote = args;
    },
    setEarTrainerRootOctaveSelect(state, action) {
      const args = action.payload;
      state.earTrainerRootOctaveSelect = args;
    },
    setEarTrainerIgnoreOctaves(state, action) {
      const args = action.payload;
      state.earTrainerIgnoreOctaves = args;
    },
    setFretboardNotesDisplay(state, action) {
      const payload = action.payload;
      state.fretboardNotesDisplay[payload.index] = payload.value;
    },
    setAllFretboardNotesDisplay(state, action) {
      const payload = action.payload;
      state.fretboardNotesDisplay = payload;
    },
    setFretboardUseSharps(state, action) {
      const payload = action.payload;
      state.fretboardUseSharps = payload;
    },
  },
});

const store = configureStore({
  reducer: mainSlice.reducer,
});

export const mainActions = mainSlice.actions;

export default store;
