import { useDispatch, useSelector } from "react-redux";
import classes from "./CommonScalePicker.module.css";
import { useEffect, useState } from "react";
import { mainActions } from "../../../../store";

const scales = {
    "[t,f,f,f,t,f,f,t,f,f,f,f]": "Major Triad",
    "[t,f,f,t,f,f,f,t,f,f,f,f]": "Minor Triad",
    "[t,f,t,f,t,f,f,t,f,t,f,f]": "Major Pentatonic",
    "[t,f,f,t,f,t,f,t,f,f,t,f]": "Minor Pentatonic",
    "[t,f,t,f,t,t,f,t,f,t,f,t]": "Major Scale",
    "[t,f,t,t,f,t,f,t,t,f,t,f]": "Minor Scale",
    "[t,f,t,t,f,t,f,t,t,f,f,t]": "Harmonic Minor Scale",
    "[t,f,t,t,f,t,f,t,f,t,f,t]": "Melodic Minor Scale",
  };


const CommonScalePicker = () => {
  const tinyScreen = useSelector((state) => state.tinyScreen);

  const [guitarScale, setGuitarScale] = useState();
  const dispatch = useDispatch();

  const intervals = useSelector((state) => state.intervals);
  const [isValid, setIsValid] = useState(true);


  useEffect(() => {
    const str = JSON.stringify(intervals)
      .replace(/true/g, "t")
      .replace(/false/g, "f");
    //console.log(str);

    if (str in scales) {
      setIsValid(true);
      setGuitarScale(str);
    } else {
      setIsValid(false);
    }
  }, [intervals]);

  const selectOnChangeHandler = (event) => {
    setGuitarScale(event.target.value);

    const json = event.target.value
      .replace(/t/g, "true")
      .replace(/f/g, "false");
    const newIntervals = JSON.parse(json);
    dispatch(mainActions.setAllIntervals(newIntervals));
  };

  const options = Object.keys(scales).map((item, index) => {
    return (
      <option key={index} value={item}>
        {scales[item]}
      </option>
    );
  });

  return (
    <>
      <tr>
        <td className={classes.td}>{tinyScreen ? "Scl." : "Scale"}</td>
        <td colSpan={12} className={classes.td}>
          <select
            className={isValid ? classes.selectValid : classes.selectNotValid}
            value={guitarScale}
            onChange={selectOnChangeHandler}
          >
            {options}
          </select>
        </td>
      </tr>
    </>
  );
};

export default CommonScalePicker;
