import { useDispatch, useSelector } from "react-redux";
import classes from "./RefinePageDialog.module.css";
import { mainActions } from "../../../store";

const RefinePageDialog = () => {
  const dispatch = useDispatch();
  const title = useSelector((state) => state.title);
  const introduction = useSelector((state) => state.introduction);
  const imageScale = useSelector((state) => state.imageScale);

  const onChangeTitleHandler = (event) => {
    dispatch(mainActions.setTitle(event.target.value));
  };

  const onChangeIntroductionHandler = (event) => {
    dispatch(mainActions.setIntroduction(event.target.value));
  };

  const onChangeImageScale = (event) => {
    dispatch(mainActions.setImageScale(event.target.value));
  };

  return (
    <div className={classes["text-entry-form"]}>
      <div>
      <label htmlFor="title">Title:</label>
      <input
        type="text"
        id="title"
        name="title"
        value={title}
        onChange={onChangeTitleHandler}
      />
      </div>
      <label htmlFor="introduction">
        Introduction:<br/>
          (
          <a
            href="https://commonmark.org/help/"
            target="_blank"
            rel="noreferrer"
          >
            see this guide for help on Markdown
          </a>
          )
      </label>
      <textarea className={classes.textArea}
        id="introduction"
        name="introduction"
        rows="5"
        value={introduction}
        placeholder="You can use **Markdown** to create your own formatted comments"
        onChange={onChangeIntroductionHandler}
      ></textarea>
      <label htmlFor="imagescale">Publish Scale Factor (1 to 200%)</label>
      <input
        type="number"
        id="imagescale"
        name="imagescale"
        step="1"
        min="1"
        max="400"
        value={imageScale}
        onChange={onChangeImageScale}
      />
    </div>
  );
};

export default RefinePageDialog;
