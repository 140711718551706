import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { mainActions } from "../../../../store";

import classes from "./IntervalRow.module.css";

const IntervalRow = ({ tinyScreen }) => {
  const indexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const intervals = useSelector((state) => state.intervals);

  const dispatch = useDispatch();

  const onUpdateHandler = (event) => {
    //console.log("onUpdateHandler");
    // console.log(event);
    dispatch(
      mainActions.setInterval({
        index: +event.target.id,
        value: event.target.checked,
      })
    );
  };

  const boxes = indexes.map((index) => {
    return (
      <td key={`${index}`}>
        <input
          className={classes.input}
          checked={intervals[index]}
          id={`${index}`}
          type="checkbox"
          onChange={onUpdateHandler}
        />
      </td>
    );
  });

  return (
    <tr>
      <td>{tinyScreen ? "Note" : "Scale Note"}</td>
      {boxes}
    </tr>
  );
};

export default IntervalRow;
