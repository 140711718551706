import classes from "./ImageGridItem.module.css";

const ImageGridItem = ({ src, alt, width, height }) => {

  return (
    <div className={classes.container}>
      <img
        className={classes.chartimg}
        src={src}
        alt={alt}
        style={{ objectFit: "cover" }}
        width={width}
        height={height}
      />
    </div>
  );
};

export default ImageGridItem;
