import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../store";
import classes from "./EarTrainerSetup.module.css";

const EarTrainerChartSelect = () => {
  const earTrainerChart = useSelector((state) => state.earTrainerChart);
  // this is the best fit set of charts data
  const createdData = useSelector((state) => state.createdData);

  const dispatch = useDispatch();

  let options = [];
  for (let n = 0; n < createdData.length; n++) {
    options.push(
      <option key={n} value={n}>{`Chart ${n + 1}`}</option>
    );
  }

  const chartChangeHandler = (event) => {
    dispatch(mainActions.setEarTrainerChart(+event.target.value));
  };

  return (
    <tr>
      <td className={classes.td}>
        <select
          className={classes.select}
          value={earTrainerChart}
          onChange={chartChangeHandler}
        >
          {options}
        </select>
      </td>
      <td>Choose the position you want to work on</td>
    </tr>
  );
};

export default EarTrainerChartSelect;
