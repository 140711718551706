import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../store";
import classes from "./EarTrainerSetup.module.css";

const EarTrainerQuestionTypeSelect = () => {
  const tinyScreen = useSelector((state) => state.tinyScreen);

  const dispatch = useDispatch();
  const earTrainerQuestionType = useSelector(
    (state) => state.earTrainerQuestionType
  );
  const questionTypeChangeHandler = (event) => {
    dispatch(mainActions.setEarTrainerQuestionType(+event.target.value));
  };

  const int = tinyScreen ? "" : " Interval";

  return (
    <tr>
      <td className={classes.td}>
        <select
          className={classes.select}
          value={earTrainerQuestionType}
          onChange={questionTypeChangeHandler}
        >
          <option value="1">Show{int}</option>
          <option value="2">Hide{int}</option>
        </select>
      </td>
      <td>Show or hide the interval name</td>
    </tr>
  );
};

export default EarTrainerQuestionTypeSelect;
