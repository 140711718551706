import { useDispatch, useSelector } from "react-redux";
import { mainActions } from "../../../store";
import classes from "./EarTrainerSetup.module.css";

const EarTrainerRootOctaveSelect = () => {
  const tinyScreen = useSelector((state) => state.tinyScreen);

  const dispatch = useDispatch();

  const earTrainerRootOctaveSelect = useSelector((state) => state.earTrainer);

  const changeHandler = (event) => {
    dispatch(mainActions.setEarTrainerRootOctaveSelect(+event.target.value));
  };

  const oct = tinyScreen ? "" : " Octave";
  return (
    <tr>
      <td className={classes.td}>
        <select
          value={earTrainerRootOctaveSelect}
          onChange={changeHandler}
          className={classes.select}
        >
          <option value="0">Lowest{oct}</option>
          <option value="1">Highest{oct}</option>
        </select>
      </td>
      <td>Use the highest or lowest root note of the chart for training?</td>
    </tr>
  );
};

export default EarTrainerRootOctaveSelect;
