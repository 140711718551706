import React from "react";
import { useSelector } from "react-redux";

import classes from "./CreateScalePage.module.css";

import ChartEditor from "./ChartEditor/ChartEditor";
import ChartDataToSVG from "../code/ChartDataToSVG";
import ImageGridItem from "./ImageGridItem/ImageGridItem";
import BottomMenu from "../BottomMenu/BottomMenu";
import Card from "../UI/Card";
import RequestSupportCard from "../UI/RequestSupportCard";
import { Helmet, HelmetProvider } from "react-helmet-async";

function CreateScalePage() {
  // this is the best fit set of charts data
  const createdData = useSelector((state) => state.createdData);

  let maxImageWidth = 0;

  const imageList = createdData.map((pos, index) => {
    const svgData = ChartDataToSVG(pos.chartData, pos.maxFret);
    const image = svgData.image;
    if (svgData.width > maxImageWidth) {
      maxImageWidth = svgData.width;
    }
    // {JSON.stringify(pos, null, 2)}
    return (
      <ImageGridItem
        key={index}
        height={svgData.height}
        width={svgData.width}
        src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`}
        alt="Guitar Chart"
      />
    );
  });
  // for the left and rigth buttons
  maxImageWidth += 0;

  return (
    <div className={classes.background}>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>
            Guitar Charts Designer
          </title>
          <meta
            name="description"
            content="Guitar Charts Designer is a web app that lets you create and print custom guitar charts and diagrams."
          />
        </Helmet>
      </HelmetProvider>
      <Card>
        <div className={classes.centred}>
          <h1 className={classes.h1}>Guitar Charts Designer</h1>
          <p className={classes.howtouselink}>
            <a href="#usage">How To Use...</a>
          </p>
        </div>
      </Card>
      <RequestSupportCard />
      <Card>
        <ChartEditor />
      </Card>
      <Card>
        <div
          className={classes.imageGrid}
          style={{
            gridTemplateColumns: `repeat(auto-fit, minmax(${maxImageWidth}px, 1fr))`,
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          {imageList}
        </div>
      </Card>
      <Card>
        <div className={classes.howToUse}>
          <h3 id="usage">How To Use</h3>
          <ol>
            <li>
              Use the <b>Guitar Setup</b> to choose your instrument and tuning.
            </li>
            <ol>
              <li>6, 7 or 8 string guitar</li>
              <li>4 string or 5 string bass</li>
              <li>Ukulele/ Baritone Uke</li>
              <li>All standard tunings supported</li>
              <li>
                you can even customise your own with the <b>Custom Layout</b>{" "}
                option.
              </li>
              <ol>
                <li>
                  Enter a comma separated list of tuning intervals in the{" "}
                  <b>Custom Layout</b> box
                </li>
                <li>
                  Each <b>number</b> represents the fret that you'd tune to, to
                  create the next string up.
                </li>
                <li>
                  eg. <b>5,5,5,4,5</b> would be standard guitar tuning as you
                  tune the A, D, G strings to match the <b>5th</b> fret of the
                  string below. You'd tune the B string to the <b>4th</b> fret
                  of the string below and finally the E string to the <b>5th</b>{" "}
                  fret of the B string.
                </li>
              </ol>
            </ol>
            <li>
              Choose the <b>Scale Note</b> by clicking in the check boxes.
            </li>
            <li>
              Highlight any notes you want by clicking the associated{" "}
              <b>Color</b> box and selecting it in the Color Picker.
            </li>
            <li>
              Choose a basic <b>Layout</b> that gives you the closest match to
              what you want.
            </li>
            <li>
              Next, Click on the "<b>Refine Scale Charts</b>" Menu to customise
              each chart and add titles and text.
            </li>
          </ol>
        </div>
      </Card>
      <Card>
        <BottomMenu />
      </Card>
    </div>
  );
}

export default CreateScalePage;
