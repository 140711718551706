import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { mainActions } from "../../../../store";

import classes from "./IntervalColors.module.css";
import ColorPickerDialog from "../../../ColorPickerDialog/ColorPickerDialog";

const IntervalColors = () => {
  const tinyScreen = useSelector((state) => state.tinyScreen);

  const index = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const colors = useSelector((state) => state.intervalColors);
  const dispatch = useDispatch();

  const [pickerColor, setPickerColor] = useState("black");
  const [showPicker, setShowPicker] = useState([false, 0]);

  const onClickHandler = (event) => {
    const index = +event.target.id;
    setPickerColor(colors[index]);
    setShowPicker([true, index]);
  };

  const boxes = index.map((index) => {
    return (
      <td key={`${index}`}>
        <div
          id={index}
          onClick={onClickHandler}
          className={classes.square}
          style={{ backgroundColor: colors[index] }}
        ></div>
      </td>
    );
  });

  const onDialogCloseHandler = (incolor) => {
    const color = incolor;
    //console.log("the color picked is");
    //console.log(color);
    dispatch(
      mainActions.setIntervalColor({ index: showPicker[1], color: color })
    );
    setShowPicker([false, 0]);
  };

  return (
    <tr>
      <td>{tinyScreen ? "Col." : "Color"}</td>
      {boxes}
      {showPicker[0] && (
        <ColorPickerDialog color={pickerColor} onClose={onDialogCloseHandler} />
      )}
    </tr>
  );
};

export default IntervalColors;
