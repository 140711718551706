import classes from "./TermsOfUsePage.module.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const TermsOfUsePage = () => {
  return (
    <div className={classes.background}>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Terms of Use</title>
          <meta
            name="description"
            content="Guitar Charts Designer is a web app that lets you create and print custom guitar charts and diagrams."
          />
        </Helmet>
      </HelmetProvider>

      <div className={classes["a4-paper"]}>
        <div className={classes["content"]}>
          <h1>Terms of Use</h1>
          <p>
            By using this website, you agree to the following terms and
            conditions:
          </p>

          <h2>Description of Service</h2>
          <p>
            Our website provides a service for creating and printing custom
            guitar charts and diagrams.
          </p>

          <h2>User Conduct</h2>
          <p>
            Users of this website must not use the website for illegal purposes
            or infringe on the rights of others. Users are also responsible for
            maintaining the confidentiality of their account information and
            passwords.
          </p>

          <h2>Ownership and Intellectual Property</h2>
          <p>
            The website and its content are owned by the website owner and may
            be subject to copyright or other intellectual property laws.
          </p>

          <h2>Limitations of Liability</h2>
          <p>
            The website owner is not responsible for any damages or losses that
            may occur as a result of using the website.
          </p>

          <h2>Indemnification</h2>
          <p>
            Users agree to indemnify and hold the website owner harmless from
            any claims or damages that may arise as a result of their use of the
            website.
          </p>

          <h2>Governing Law</h2>
          <p>
            These terms of use are governed and interpreted by the laws of
            England and Wales.
          </p>

          <h2>Modifications to Terms of Use</h2>
          <p>
            The website owner may modify the terms of use at any time, and it is
            the responsibility of the user to check for updates.
          </p>

          <h2>Termination</h2>
          <p>
            The website owner may terminate a user's access to the website at
            any time for violating the terms of use.
          </p>

          <h2>Contact Information</h2>
          <p>
            If you have any questions or concerns about these terms of use,
            please contact us at jambole0192 @ gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUsePage;
