const library = [
{id:'0', name:'Intervals/', children:[
{id: '1', name: 'Minor 2nd', slug: 'library/intervals/minor-2nd', queryString: 'comp=XQAAgAC4BAAAAAAAAAAznMOiaQTek9SXYNXSLuP-JPKi8VMbM6jGjZRrs2ws6aiJLTKQ-SZGR9-b1TqlU3_g713Gi2Aet80Oi68txjr5VD_u5IzRc2_ZhU9zGLawLoyhCpOSD8Q3NgjsYmzPikWHHHRxMe2CRYbtbgzSPxnVhQrXXOciztk7yVHCqkFg8C5iW94I7YgkNV0Q5WloafvR3OOeeUl3qs-qvVGlKTYtiS1KP49GuTEoe3CBkCAdjnw67IsUR_txaNYmrfgCk8I2NL3SgaG8VkEaDjkKm_BSMYgFjrfEFYtftRJjjuCtjoAY_pVHC8agESzgXIuovsfoLaZS6ds8E5XyKGlDHxRn1f_2NrW1'},
{id: '2', name: 'Major 2nd', slug: 'library/intervals/major-2nd', queryString: 'comp=XQAAgAC_AwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7HywwkwLt4lsKVUFIocw7XcmenniMJLFiO-sHiX7Ba6TwUrSXrsj24172KxHiV7QJvOtF-DReIMNTdbZs7UV-VGw0U8jJlTgqTk8wbhum5KdtVuuMC9X7ts7QjuH5JT1gHqpbn4jjb-b9sBt-qKvLweU7JtY-BpOyimlO2D-jnBbA_x1GmJacfmPr9bnGoxc7_jVkOpTMTcv_dRTofzKmXaMZHwubMFh4Ti8PwzzMHShSzQ5sVBfMbECuJCeawy3DvM4TwVxfNjZ_yNkbNCpM3sV8cZ-zqW20I1V7cQJ0a1P1Ej9tb3E'},
{id: '3', name: 'Minor 3rd', slug: 'library/intervals/minor-3rd', queryString: 'comp=XQAAgAASBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7OGEEXe78KP6-kynbAHmzC78KTMSc7xFh10pEz0sFi06QDdFIT38f8lPkpYWH77ooJypT81e5foQGzazwhfi5XEQwQBrVg6uP8wyE2Ek9QKaOkvLVHePDDn1t9KSF07i2LPY5hG0JjtLvjaX2sRqagMXLtme4KtDWzQ26zSuV2yTQ4hXfYMkdJAja5ufrQHosQuR6JkaFc-eVUW07a2sHbyL4uFVH72jjuNHoMJaPSw9w-qJ5JglwaVrkr4RsTjzdGAg6W1UVj-69oidmb9bUdc8B0HA2brJNYtWSCYh4iu2xQEph6aOYFr9fAgQ'},
{id: '4', name: 'Major 3rd', slug: 'library/intervals/major-3rd', queryString: 'comp=XQAAgABlBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7Oi2FQbPCzbQz2pOd9QOsnLHyiJ9NxyiEwvb4UUdkrcINlKkNziRuTv59Syn0Hu71RmC9TD22ECBs_6mEMvTGj3WRMWMm9hwE1btO3fMT2JsCd9bk8SkE1RRjgSSwu82Y-8E2Ajz7UiOQ6ijTFwlz2pOPXyZ6zj2YDtvvwcfv-CH4FomrtQJ1aP8Qo8lqUNMwNwBit3w7QCi2MXpuLZwgtTkOThCMvBFEF10S6Z1nnElluM0s8gcVpFYjAK_v7NXYTecVmxCoDA43uLBEt2TQU_YB2Zluyzi67OIH563igQm_xYHLFFgMll8iJ8gQ-n-dJ8L'},
{id: '5', name: 'Perfect 4th', slug: 'library/intervals/perfect-4th', queryString: 'comp=XQAAgABwAwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7O1ejJAQ7i5N7h8c9ItjAOnwmqr_hkHkfrCNFqPrHfkn9LfgQo0JVcMG5JhbQdkt7F6dSlIBm82Qrr5VPfXCLawrHoT0c4RHi45HdFhjNhakPiOz9AtQhYTV8npdrq0aCkjlpYsdOcs5uNV8q8nWJV3UhL2x--A5GTcF6QM0RJoqyihvVgEl3lvULKYw7gtGsAdEDBErPLsgZDfhuoRb5Z5rYoMUQGX3bpY4nhP1Yqjim1zZgpCsPyAP8LA7v-PnMiwep_iuGtmV7mSfi0uJcvjBg70Tbf3_jWxUYA%3D%3D'},
{id: '6', name: 'Aug 4th/ Dim 5th', slug: 'library/intervals/aug-4th/-dim-5th', queryString: 'comp=XQAAgAD0BAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7PDteCgSHvE-em-HYRENQaRnv2AqsJNUIs51SIgDXS3UFLPK7OtmyT_mD51hdpgJsTp1HoWaGe3EGiib3Vuj_eNve7P1HlJ7Q8osiNvD6BhJd_Rfo1DuSRNWEs8kfaCY_wAgftEoFZlZ8d5AAEDilSnAnEZ6vh4Uw695KWZIw88pzNgVGGq0BGNCt_vtS1sQLF0hjGXP_PztYlL--zHPm7nLYtIoMhsrlAMnh1t4-9JmZqFfkMTvMJ8Q3emauRuuzAeAFYa9va3VtQBRKTBuligJuLrwCdKjts5i7duKz8eQxcCJmPDY0BftZj2MvAAZAfIS5KjmkJ5LQEE8__J627s%3D'},
{id: '7', name: 'Perfect 5th', slug: 'library/intervals/perfect-5th', queryString: 'comp=XQAAgABvAwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7PInlo3bOCg52jh1rJBUNgCm2FPNbD8s6rCCDGM8JByiqKSQ2H7n99Q1HrG3Tf9DDIwhYXYLtP3VD2Mnkdst5DCmprZmqNn0e-1B8Mp8d-XC4eAFZYCyjfwNi9jdP1DM1EwSjWPp85sdmyMUIvFEz4Mm8ZUP9daZja1sqkPBV0eV110rfhETdOZcePoRUXG8b_3OWe8eZ1ierCB7-99AP_7Zaf35aLR9ASd8Gtqisz2qQvO3xipsT7eTsgIxd19MUyGUiGtankB7IE2-cwl32RlUaUrsb5j1_-zjrwA%3D'},
{id: '8', name: 'Minor 6th', slug: 'library/intervals/minor-6th', queryString: 'comp=XQAAgABlBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7PJKxxzrBRNf9FZCJvElgfg9OBKYZlrfsFYk59IneBLXvvfUduKg2GRYGNPUaP4DmAaYGUfcUSWJ1TZEzLYdgoFgzuPNBnaCryC-Au7qnaooYrMVdqfKZV_b4JZro0TeQVGtZJKuDHkvGj13-D0Jjh_LJ3w6_EYQyKLyplvhPeruMU5KIYGCEJgVhKr_AdhAG7gGDJFbf0Ykr77t5GJsP8h35zR4zCvVlXGoh3SQvYDyLBuPGWtd1XAFaXct__N5hiAMmKlBWoBzOFyLDL1A71RgotPB3nLmfJAb9PtYCdXM-O5xEUQ52Yb92JrhR__tIm4F'},
{id: '9', name: 'Major 6th', slug: 'library/intervals/major-6th', queryString: 'comp=XQAAgAASBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7PJfw3QjgoeqVqUeU_OUE6OTcSQ3dwaTe50-tm9M_HBFfBEEbWeGE3uvgEA93s1pTUMZ63PvXhQ4iAqIbR1Fv4adpUAsGjseThJy3wrNmbjKU_pQAjGlsRFKZJ7uNJx6pllW9mTwiyShfxMghcp4HX84FmCnRWCbkjbIEq1c-DTeGs8nymevi1lHSMJqJSpkig2zHaRDmnBRgq7eZBK9jsU2F2HCxGp_Si9v9exqncvGUWAV7eQXyZuHFTm1Dkhm_1VZumvJNsAC_IQgpyo-CrG0qebq0JsGhI7wmD5uyLCtx9QG81SMkjYo6f_tszii'},
{id: '10', name: 'Minor 7th', slug: 'library/intervals/minor-7th', queryString: 'comp=XQAAgAC_AwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7PKCsEdzISDl500r5p2UlmPScDXow92YWbZMo2E7e69XKiDeJ-b8aoa4bTNM-s4aXPL36DvMAHDhFya5cHiLSase9xeZHUxWNbh8-ohuU0ioyrHHxVMSEduUdy23W_qoIpTUY7VFN3uNbJeziQ9M2I1Bv7xS0lPhLLI6aNmzFcWqA6mzSREDG8dUS94i1X1bN56YtQ_bGmW4JmTqHz6Zaz2iZBZlReuZkp5u_2yfqJhHpy27XIkFncpnd0_iXrfUiehjvzXQhccXmNdvhfcDJz-YVpjIgpsr2Y6dF3FP3QGIs_8S2vMA'},
{id: '11', name: 'Major 7th', slug: 'library/intervals/major-7th', queryString: 'comp=XQAAgAC4BAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7PKXgaIVHT2IGtlR-35iF967EsvkeyVQCYwWbYRxWJzN9CFvL_6BepHuH-Ua2RGw1T6yw-AnwibNpOkG68n6X9KpvqTTP2x_50QJe9UPuQ00Z2QS8HU1sbEj7-V9owkI9iaSSGDr5RztG_jpz0mIhDNIbpTuOx44NpVZzhnrMghyEvhGElReqFFJ3xM1GftG2gMb7bDyfSrcjaKQVn2sN-d_Tk81mCGTWjg2BRo5fvV7r7sTzFQ_6OdhfM4pBo7SB-acCYiv2oltgfZdLsiUAhgFpSZ8MVZik22G2oMxo8InFDVEyQ3m-YZvShVDTikw_vq1Avo%3D'},
],},
{id:'12', name:'3 Note Arpeggios/', children:[
{id: '13', name: 'Major', slug: 'library/3-note-arpeggios/major', queryString: 'comp=XQAAgAAfBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7Oweg9_54Wg-ZRN6CjN6D31zwesW1KTIwuTXMaA64S3v7xjfAoVgygrQyyER_qBp1Eg8WDZRZEZkolmaBgTpjc7Xu14si5dJXOp-XJM-itIc-lqDMAy5crmLvy_qkiF23RpX4PQLV2r_f_0AG8iXz_JGKSjupY_ODsKd4bq7Tiq32uXO3fMZ1L9dWsbTeGsTcV59TDdFiQ5WBnp48kxyxAceO4jyGuCj71lLQHMXHsGFR_6OvVWC9K2t_86_rwA%3D'},
{id: '14', name: 'Minor', slug: 'library/3-note-arpeggios/minor', queryString: 'comp=XQAAgAAfBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7ObULo7lLI1w4sqSuSszdE0-XAQzEMgvJGxnWy52A9X-x4SGcRBVHraYtu9ILhs6rz0Rz5yLQOXugkfCB5RFkHEUVFmXBUICWNNDqSZedXruY9OusnkptIkv6ovYTYF4hmBOtl90KK4XiuYS2XRkVy49Mi8tIpQkXzQr3YYwASUtDr5fKpB2e4K6WwgwAsA9Z2Mr3OG4jiDbtu-qZD6vfZ7IREbX84nNoM15MxEjJFk-oIZS_35NPpJf_4ieQAA%3D'},
{id: '15', name: 'Diminished', slug: 'library/3-note-arpeggios/diminished', queryString: 'comp=XQAAgADLBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7Oby71_AfDN4PboInl5dab9IJhF9bYZlJteA8zbunC8xF8Gd6WiLul0uo7UtXy0ht9dA90zW_ycP38LIb1ZVyNEc7CHjM1WumUHw7RYvL68s0bJdviqCmMPJBiJX8NR-FkLeE4pP1rMEstBSzX0EjyJ1z5GBwsJKQ3wIVGY1qcjbdgBbD7Vl-_9vngCs9R99fU7YPTjWYm_x_auSg7sy4-h4midHUV9mLFvOWhm8gKswWuybekXjjDD85xKT64EVMUAVjtBZdnXSvjQXzYKADKehrQa_w_3oTrE%3D'},
{id: '16', name: 'Augmented', slug: 'library/3-note-arpeggios/augmented', queryString: 'comp=XQAAgAAdBQAAAAAAAAAznMOiaQTek9SXYNXSLuBa7Oi6ylvsFF86f-LCIrJ38KQ1nkxoixNW3_AO-sUmiBE3uHuA2PgzpbROy1kKys4e8cxiK-uYH2NNliQlQkg4HJJLcjIE_VzOa5MwSPv01o1ZIgL6UVYUvrxFBaTjQT5mIBSzXVMEiFENE56En0FUisU31UYYPyqTCmLm9oY4pZlQxcHdrQqDuBo8goWczjtZtOBJJny68fGN7OYCBoOlbVT1zP_hlF8f24dP27vhyDVubaBNaxROdoUNI7Nn_-S3OWE%3D'},
],},
{id:'17', name:'4 Note Arpeggios/', children:[
{id: '18', name: 'Major 7th', slug: 'library/4-note-arpeggios/major-7th', queryString: 'comp=XQAAgABsBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7OilAkNpW5lD-vpMp3g-rdZOXfjRL639gxcWoGxgD9ODQeyu3o-0yD9E4bum2uZ_aVUZz6pGFZE8YucdljiFG0FtuoYWrXZA4Nkt2Pcvj70FW1OJjm0PrtDeTu3_NLu8hTlMR2lkuEZV36l9pIkdhJVJ04TinkXYka0kptl73P4cRdkcDB4bcQt5q_WXH9npggW-sIvJOtsN36Gly1m5_Yb8XUAK1aGzUCoWcawAMcrGlDw2EUQG4XIe5CrHiFjrV9H-smXz'},
{id: '19', name: 'Dominant 7th', slug: 'library/4-note-arpeggios/dominant-7th', queryString: 'comp=XQAAgADDBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7Owehkb7Z4RAzRugSddgTWCYFu2g5HRxHvz5smTjKAcR0RAUZTOcp7LQcbJqQNMW9PKW1yAOvbPVil51RKkATPcbsCwzvySh1WbMPNcK8Y-9OtKsBm0esJOdpXzmTc18FVx7j7CmBoB8tAwzXLLsILCXPPpiEO8AcJ-elpvTBt1IoeODSrkLIJs_2Wggv8Su9gpQpN2j089eNwWc36xlpNammXE4O8-k_gshCEoeCGqdjmlNjzZeS2rT-xSx16M08NJWOf1E5h0%3D'},
{id: '20', name: 'Minor 7th', slug: 'library/4-note-arpeggios/minor-7th', queryString: 'comp=XQAAgAAZBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7ObULhO4Adto045mXGxpMQaayKgF4LAX-2Rc3KhFmPhzUYEjIVFta-f-a9Va6Ehy_uwFF4uKN9fUOLMaNKwfIoikW5OCE_ooguG3LNFxG0Wi41p2U-Yj62MvGGBGvYIKV9SK-c8Hms8v6MwMz2PR_ds7thyO4z-s7etHJ_ZXe1NiLL-DLTqyyLttXUz9h_gpSRebZ2M6kpoTWkyR5APIjsQrQ8NNQ_Vvcp4uz6I-xHd3R5FQDC8neTZsIwDN-qkcjg%3D%3D'},
{id: '21', name: 'Minor 7th b5', slug: 'library/4-note-arpeggios/minor-7th-b5', queryString: 'comp=XQAAgADFBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7Oby7o5w5MjomWz94MN9U7p8XpJyWeQItg2nsi8Emf6uk3qEy4h6TbtfI4fW59PvuUtYNxPySDRg0hru50AvVJhcJkk0DEGZk5-a7hyYOOYNTkzvLZaulNo9mFvviEmTP_ahS754VHE5bc3xPo68ITIdW0Q0XLPAYDfCwQwkq-Af-8yyqrHzBmash_qaBzuL4LEW-XaDQXEugMlxat0aDhEbUJGudd0_MuZ9wGhRO9i5dKsU26ix0C2tSL3shbIBP67yHc395Aow5pH7Grwy'},
{id: '22', name: 'Diminished 7th', slug: 'library/4-note-arpeggios/diminished-7th', queryString: 'comp=XQAAgAChAwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7OGu1y6sFF86gWz3Ds-WPDVi6CoqP6OHgGMpx1iFxodvYIoT0V8zJNY73tW1XuyGAgXrzg24lv_9_pw_ngRyvyihYeXXSDVuAGvbJvR5gaq8qykqL4UBE2vwvozTfb6CGhGxaRc9vmH38K1o7CKfhTP2c8pIthc_mjBLsNQ6rg3kJ1kfhXH06FBLAZKmRMpRyDqEeeNgVV4yz1A-ioonX_GdGMPmrOFONGpGUXtF9rwpEBrxt4Wod-8agjCZvZBJc_Xz9NUI8Ihg5OYZLOIQdCJ_Tk7fXAN8A7oqT6f7McRS9HiGdJhe1keh4nraUAYXLXllH9ash4JHGBBRt5X-inDwl3G4fjLSN-WxNTKga0RNxYvxgSBNl-U1ff6I77I5eNooS3AypEvyGByxgdwZQ-10hH2Y5uGIiYC3T4Eu5YTVhmiEl3S0uGlFDtqGLEyKl9kW6JF0n99cAmwheUYADgnILLlt_pbQ3g%3D%3D'},
{id: '23', name: 'Minor Maj7', slug: 'library/4-note-arpeggios/minor-maj7', queryString: 'comp=XQAAgAAUBQAAAAAAAAAznMOiaQTek9SXYNXSLuBa7OGED9sJId8MTYq923PjwXz4lM2kWcQL9Rypkf-3Vl7su8pUL2jpWLjdC3KmfQIXd1zhCERaaj08ZXm1CorqNq7TlpjhWfseWm6qcx6YG2-mhmWZTmyRLvwU0c5PHPBRH1q5_1g3dmaWgDjNugi4NUwFhbC5Rw4tFmdPKATOxo3TD-cOawhE01-id1AYaCueP0wvSMn1agWi_At-AeqEUbOufCK2jXq8zxxnYBh8EubZYR6D8gGpgLJgRvLZdvvXSuC6qMsSrU5WiIKFfwxgcg4cwin_9WUTcA%3D%3D'},
{id: '24', name: 'Major 7 #5', slug: 'library/4-note-arpeggios/major-7--5', queryString: 'comp=XQAAgAAYBQAAAAAAAAAznMOiaQTek9SXYNXSLuBa7OyDzg6SnVaBS-R-pEOJVj179PJjVbOoy49d3yFzCxLqKcrkpVIhpLDC7BA4sZMKXR16CKPgtkc9Gzq5o_P3G70DLT1qUI-TCfcCsIxVK7ssI7LhqEoXSl7QpWA_fIZhER5GNBLV4wlBLo5bd_RHCHRpQO_icwPStQgBji4TppE7B_giyeHLloQHSsmovxsJw-wDPnCfJmBXtD_W9le12w6KNwAeBBOdsrWT5Ugn-BU8hfePdPTSMqIYGNsdkdm12KW6xHiTED4bYDXsLoZRLwfaAs-Z6ML_6jxBhg%3D%3D'},
{id: '25', name: 'Dominant 7 #5', slug: 'library/4-note-arpeggios/dominant-7--5', queryString: 'comp=XQAAgAAbBQAAAAAAAAAznMOiaQTek9SXYNXSLuBa7OyDzg5_qd2j7bkPWgXDXFG5o-HF-K1CgHWo67Ub4PUsefUOmXUYRLs4oc8KrpFiW5UlxX1t-Lt1BE6uE3uhRBREVfojActMIkKV3_I2OMS0ccM_mnZrZ6YM-x8ghOavYuDIlvV4EOKbO5pJoSGCg-pyLe87VCQCO37vwRG5tTRdcYlUbG1DQKmWd6qFFZlS3-q1ECinUaLTKPZcaajqG7ECqdaC6CxO9zaZX0iWrBGtUgMnunHYTXb_WKQkNlB66JIH0jDWSYN-ITqU44vNGzXWLv3zifWw'},
],},
{id:'26', name:'5 Note Arps/', children:[
{id: '27', name: 'Major 9th', slug: 'library/5-note-arps/major-9th', queryString: 'comp=XQAAgAAgAwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7J7p0EFoh6WwiTPtZTlfLmNu9aBZ24Z7hUxI6m9T2vFRF6PBrrD5BzWF2Ok9PW19BG8lQWexjM-lzbkhKEzvGpmwRn5RPUiRQhbTPWFEDt9Fi1nMhUg_cSKuRJNjY0hzAjiSIkNy8mFVyhEDgy7aVlktXsltZIkF_tcLpP3NWMzdvsRpjiqQ4dwfUC7jUobp1zRUvPSY-hjkJ4J_sSDLNd1tQP2KvYipuVr-_q0kT1qR3_5WzsI%3D'},
{id: '28', name: 'Dominant 9th', slug: 'library/5-note-arps/dominant-9th', queryString: 'comp=XQAAgADDBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7J7p4GtiObLyVGrpKuSBVXDCc0_7lTtoalAT541IiidB8EtOB-IPlc_bjjzhTjsdA6p253oOfuKe_omJOyToXrZNyrXu5t08UFFH797hqKBz0DXFyePoXkcJ_T0y80vdoiZD0ZetrTduebywzf6W-JkpY9PGtvjqumP3jnSQgqoRQPI6Xz3fSQc1LRuJxXxrIRCEHCGbTZTFwpvCQTqhLXxdRMWETgw2Nmmw2kR5J4JeW7lw8etsGGpW1Ea-YxC79lBmQen_5sE9rQ%3D%3D'},
{id: '29', name: 'Minor 9th', slug: 'library/5-note-arps/minor-9th', queryString: 'comp=XQAAgABtBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7GdZZ6ciQoEqDhobA9Y6EzgXl0ADx0-pbm_SIE6du1jayH7TBQ6K755fPZ9nFVibGHdwt2nBfHy00YbFplxvBPeRvFtxegLnBQUBDL39HtzqOqjEFZOpOTIfKoQ9EsHqGz-j2Er6IpL8KwtkoLrJTDGqK1fN2deFBWPOUWeT4RzpVYvtR3KdHX47gmfcIZ6xHvBeFoIFZDnY9hdtkZCDS1RQnc9j44ZrqmRIPOokXF9heW4YK1I4BAF7K-nMqfDgmZwx8H68AdykkKh04hGV-tSjLQ%3D%3D'},
{id: '30', name: 'Minor 7 b9', slug: 'library/5-note-arps/minor-7-b9', queryString: 'comp=XQAAgADCBAAAAAAAAAAznMOiaQTek9SXYNXSLuP-JHxX5qQrEj0eKJSKVsTjNDlhD2gpwf06BRGh-66mfiYTmiv-62ZDEczvpt1lWz7p3KZahtBxz1iWJfYEyyvuB7gsHW814ax5ez7HMvgU0o7ruk_tYKBS_-xtvc-kh29ljlkMZ0GIIOV5VvnEjVvYdmQyb2SDdI8j-UTRG4qzKjQRMbRXldYsU1lU2G3hQ_mzgprh8JHKNy4fRNRsvdp3HfiTNNbQLyrtz1GffhJcXOdOweboiHLUwujZPRYFBcY_JJZkAI2RI21gKKNOczs2nf73j_vN'},
{id: '31', name: 'Minor 7 b5 b9', slug: 'library/5-note-arps/minor-7-b5-b9', queryString: 'comp=XQAAgADIBAAAAAAAAAAznMOiaQTek9SXYNXSLuP-JHxT_zvNrw8_4mckpltNOX1IwJZziOBrdM_upRkstQhBoTkOx89Q88XAWmXAmR8nvpBzayxmjFllWqLRe7FSyaETh61Ks64BBaxBsOuP9QoPYAkr_bK0nWTWWpYvJFU7CLTj54sBsd6yzQRRREVaLrNTDZQHtZlomrNOyrRDGAfGZ_FLd5wzmMgrp4zIz0MThytegw1fI6PnuHau331AAxkTLDl2U6aABs0BF8tC5EtCBCqozeWI55uiF-Rq7LLPeEaGBsEFOaIV67tiFdRiXtgf5BQD08S9lP74Yaif'},
{id: '32', name: 'Dominant 7 b9', slug: 'library/5-note-arps/dominant-7-b9', queryString: 'comp=XQAAgAAZBQAAAAAAAAAznMOiaQTek9SXYNXSLuP-JOGuajr77MXWINaDVF2FC6_I6NG_u34MyUwqoYepmp6g_NRs5jaSHT8bnwjUOL6NmdP_KJSt-wijsKAK3Qxn-0SNB6Sr1SgVLN14-91oTAAoca2sMEosZng84uxN0dW43ZPn1GIeLgtIDZevp7xT5rIafTevzbMgJDhjMZW3YnV3wWwWN4n3KXS-IRU41C0ovyaS6kr_alozKNXr6lqsn6hZnagEX9tJOCwFWR_agZp2TX3E3nOQQG7B1N51vMb-MHHhuWCvZH6DhTDrI3cMa4T7ti_Jif0Wqhc%3D'},
],},
{id:'33', name:'Pentatonics/', children:[
{id: '34', name: 'Minor 7th Pentatonic - 2nps', slug: 'library/pentatonics/minor-7th-pentatonic-2nps', queryString: 'comp=XQAAgAA4BAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7OYoadeB-Cx1c7PTUoofMkAVU-ZiwNHJZBYBXBYwYFIdHxUuUdJ-YYAr5Z4_hC67DCQPja6P2JSptDCYF3rC3J_oZJBBJhsqMvarBHg6AQJOBkj6DM9a4KhaNlrgPPSklMmvLCKOZYBCPTiHTuldsbCt63tna4U9377IN_RXteso8Mf7-sfYvb7W-wKYI_o6fP50S3iiF6q8ZMkQDg6fDS5MrrpT9aBXQskiGXUPsy24gDt7jUBnYSaFKMDLYwtnYTLSvZ1pKAsymLJITl1b7gQ7cjhh7vantjKITzpYZpM0r3E17JdZ_68K_eaEXAlftZR_R9oOzImAhKaqRY0VGMo2T8SpT-pTGdOunpB2o1fH-yqVlHbZ4DCWCIxwMHGeF2O2HIeeeuDHC6dq_bxGqNt5HTeOvjrTIRXHGLAC2Tb384G6ufl7WV77_vy32w%3D%3D'},
{id: '35', name: 'Major Pentatonic - 2nps', slug: 'library/pentatonics/major-pentatonic-2nps', queryString: 'comp=XQAAgADeAgAAAAAAAAAznMOiaQTek9SXYNXSLuBa7J7p4G7iNtlI2hBG6McdiR6Iduv1EJPc9GK91m7d9IQuz00zvOXMUFhjRHTVbWNeJiE1saaBbubLV_zEvkBKqdYNxJbDt5PXXdBImXH2-oGzJ8n6P4KZdWvv6lOx5e2ZTteYpVwDGKHUNGcWeqO3NtGVCSwXmlnJyTtLbStmmJkCQHyLHP1f7CzQLyfU9QHwob902mxAv7wnMaox_ve3SgA%3D'},
{id: '36', name: 'Dominant Pentatonic - 2nps', slug: 'library/pentatonics/dominant-pentatonic-2nps', queryString: 'comp=XQAAgADhAgAAAAAAAAAznMOiaQTek9SXYNXSLuBa7J7p4GtiObLyVGrpKuSBVXDCc0_7lTtoalAT541IfbDK9Xu3eDHvA5xPz3sJH_tv76PQ_7hXEmu_k6id8c2LO6rXyJdUAO4WzSyDorMs3kMkPWitFyfZo21OjcZW5KpeKsI2TDzdEh0DnZTNQlIHkZBDIxRLOC2M_IDJpCj9jaLTTTVyhb3MPtMiqfsyrzBinHbXfBsu3yJd4hf_OFD_FNIAAA%3D%3D'},
{id: '37', name: 'Minor 6th Pentatonic - 2nps', slug: 'library/pentatonics/minor-6th-pentatonic-2nps', queryString: 'comp=XQAAgADkAgAAAAAAAAAznMOiaQTek9SXYNXSLuBa7OYobG8MXigJ5PzQc5qnSJzq5VJBZjhD9be2CPyMfIxkZT_xcCUZWiiiQBgZLzFMpWSpwnVGSAsDMDLRMf8WlYhDcMJfPuMMECyW7BtX8XXXBqxly-k6fpiIvXRFCtLF7Is-8kSZB7HJuqFIAZu-D7Z0qWhnQYkvDAhcA6oKmxJCspTX9ODMdgPss4byxhxWwryvm9sPjNAVFBsg0NftBtb_7-KF4A%3D%3D'},
],},
{id:'38', name:'6 Note Scales/', children:[
{id: '39', name: 'Blues', slug: 'library/6-note-scales/blues', queryString: 'comp=XQAAgADGAgAAAAAAAAAznMOiaQTek9SXYNXSLuBa7OYoUn-3_-K3psuRrI_MC-khyii6A5JFxMOEpayg68GqvBFqHP5aWSUTCMzOgSAz0j6unpxCEA9TLiTKtuFdkdCQW2ps7JR3Bys-xtC7mlYlE6__S0OkMpxUFxiivWCDim-O-A475C2Aa7zt--swax7z0Zcjf3QEWQX5X7mzKw45X_9RVwORI4X7OcT6wtRIsRXKoHnw1gcWc1O7ZPEs__8RQgAA'},
],},
{id:'40', name:'7 Note Scales/', children:[
{id:'45', name:'Major Scales and Modes/', children:[
{id: '41', name: 'Major 3nps', slug: 'library/7-note-scales/major-scales-and-modes/major-3nps', queryString: 'comp=XQAAgAB0AwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7JFBoDGNidA-PNFsgJ_uMpRihF-P3CzQbOlmvUpPej-umkqzCDTNSZ_4bcEE7uyrcZw-P3H8qMExrGRsLVcEfBSeVVQZ9hWy5UTJUMBJtG7kFSkhah5F1mI8yvXpUTJr3chMYA2veliWdJ3knelFVPGgyEx9FYJumXBvKWHtb284FscGXtQo2_L3DMJ3IcHZQ3ekboqaH_9d2l8A'},
{id: '46', name: 'CAGED Major Scales', slug: 'library/7-note-scales/major-scales-and-modes/caged-major-scales', queryString: 'comp=XQAAgAD-AgAAAAAAAAAznMOiaQTek9SXYNXSLuBa7JFBoDGNidA-PNFsgJ_uMpRihF-KCP1qSJENcVfEHhkxEi9pzycAzEasyKs5AEd4a-eo9E00XYyzWMs71M5T7RSN1pUoyHIsFqiIOVEgfc9ZrDk3QA6Lga30hrr20oCddciXBGKGPEeJf0q5L6ZRzJVM-72xOzDvl0BKuUstFZSoBRQnFM1NAVHOVaboj5WBRQcF0K1UibVtN9FPfeajtA6JX2eDWRlcUBheGNUbEAQiR1s0p4UgWRbyDkFeLMMbL5z2clX5n_8Fq_AA'},
{id:'54', name:'Modes - 3nps/', children:[
{id: '47', name: 'Ionian Mode - 3nps', slug: 'library/7-note-scales/major-scales-and-modes/modes-3nps/ionian-mode-3nps', queryString: 'comp=XQAAgADuAwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7JFBoDGNidA-PNFsgJ_uMpRihF-KCP1qSJENjCfOQ5gR1Kzjq4kBKChNpB5T-fgqxhrBOfoIFDvFOPzCpOg9i8Z2hfMRibZEGKNem-Pe_zQS-jOeablsYmihObvIiqmyaHzF_NNCuYuTa3ucxsDXUPmItqNGjD0C1wGB8-B0xppGCO5jdktaydBtAieOe_8WrfOTxMWdxFImnT7jn7NyOlhT-Dz4odSQfiNV5voxfEH4HmpesvI5WMGvnDsKmTfX2pS6hECNiYcK9LuyFSJsqVRBLQx2GPP9YmLX'},
{id: '48', name: 'Dorian Mode - 3nps', slug: 'library/7-note-scales/major-scales-and-modes/modes-3nps/dorian-mode-3nps', queryString: 'comp=XQAAgAAABAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7HWiBpiI-aPCwl17Uk44ecqNiQ6wFxXhzTFigezPhiR10my1t3EdVvcl_EioiAmawmudP237n5AaHFTubMY9ob4n4UKbd4YApURYT2tXLUkJIB9xxZx7fjwF5GHWfgONBDc0ctg0wd2MZRFCwcOteSFuza_Lx4FrDYoiq1tI7hO0ocb-aK39g5AYsXH2b3eDzF9BNm5klI7rZaxSpS-Zl2Dh3qtZd6TkBWYlVArbmF2MQC-2PHse25jb42yJ08RYyXYTYfi98FD4x-y3O_RAs77cYOkh66OiGjSCZFs0ib9C__ZI-T0%3D'},
{id: '49', name: 'Phrygian Mode - 3nps', slug: 'library/7-note-scales/major-scales-and-modes/modes-3nps/phrygian-mode-3nps', queryString: 'comp=XQAAgABKBAAAAAAAAAAznMOiaQTek9SXYNXSLuP-JJ7ymTPn9jqS3IoCLkHZEQvckX6nqyWZdNvGi7Q7lX_UhOelIWlPJLFkUb45YlPgWjy79OwfnnbqzDD3MedWYZa5WPnSLUuXqQ-d_WmzlescuHegIubIb9iaL1g-1dfIQlpQZp-Raq7dm-sNSKyk6TdUdWAOa-ySNQjLnnKwIU859daCYK2L5ZSo3otF6g1a3kw-JqX3EXw68JgQhZvOFP6C7AzrwxIlosGqMggLJrTqpBb7ie1wHYTjzzKvd4_CKZcxHAgVHAg0gWsyVSKF8pWB2_5a0HuxEopWY0wckcrEmZECfhwl5NY9qtX1HxYz9_7ZyfggFeYuLfXY1_317cNl'},
{id: '50', name: 'Lydian Mode - 3nps', slug: 'library/7-note-scales/major-scales-and-modes/modes-3nps/lydian-mode-3nps', queryString: 'comp=XQAAgAD3AwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7KWnKVepYFoF2fdInHdkpvZyWptYgnXt0y2_9KyNnpczaoNaFzKrrkaGNjnk-gBACdPF5pNnz8ndck9wVqq2BFd4O2YTomgU6_vzUKT5u0wG2rYaq4CuVlkFVACHz1eWyyvLsPx-LrApm__5CUW1Ocai5AhayAc8R_CVw-LYYu2Gvnu4X7Xy3pCAuZyvbdnYRrzp8A5egR0xlrx3hCQ6Rvu3bERQh8inTAiMBbqi70MkiOAN_Ge9JBdMW8hI305p_fVStVYTQBhy6627AVmM-NgHiph9S5Q9BG-jP__TW5VA'},
{id: '51', name: 'Mixolydian Mode - 3nps', slug: 'library/7-note-scales/major-scales-and-modes/modes-3nps/mixolydian-mode-3nps', queryString: 'comp=XQAAgAD5AwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7JFB6KB6yog48NRG6McdiR6IduvOM72PRoSiJTtNSC3HGzXGZ3eeLnrnskxqNARoD1nsl28xazzVDFYmJXSrUw7O0oFo94eVo_KJjU_05oojbD-iySZSdmDoCTNvSP7OHF1QhK0pzj__ULMsl4ub7lo3MbdWPwVs3iya5SxYLQTVWkaOqqBOOcXwIB48fYtK8Xqi5zEGgUZsmZjVE_gwdVpULVUfYvcyMhuguvFE6CjoAlzS5j2mRnRgwxGhp6a0b0Y-HuzP0CxO_DWbJCGP_YEv-k-lSpjQq4ndvkWm-kABeA%3D%3D'},
{id: '52', name: 'Aeolian Mode - 3nps', slug: 'library/7-note-scales/major-scales-and-modes/modes-3nps/aeolian-mode-3nps', queryString: 'comp=XQAAgABhBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7GAWQHLNC2RMqSCvrDWnHO2HLdVs37i44lpi4TPlw_u7cOOcMMvGsGzN8agzjNsrkigeg4CeJ17y_pyFDmU0mpfrGZsNJg3x73SdyUTqwBROKo3xgf6dy2FqcEKzUOZ-drcQfez6jy6JkfaRG08GZ-JG0yXa-jn-TT-Wy4_iybkpMiYwDYqyw30M2LTaGu-ce72dBDii5dVIbLlgBTrcRNxXyY_ixZoH7bl8hOnDHufNYV90ohKk83jx_Xh3mb1dqe25kN-OeJOc-Xog6PDpD1_beXI8bdj7ALrtf9G0_KqltAvZ2uK0Ka4KyivkliM0vcEX9TU3wDycCxgrCYCEtGlTqH-m_nFqNQ%3D%3D'},
{id: '53', name: 'Locrian Mode - 3nps', slug: 'library/7-note-scales/major-scales-and-modes/modes-3nps/locrian-mode-3nps', queryString: 'comp=XQAAgABcBAAAAAAAAAAznMOiaQTek9SXYNXSLuP-JJFKDNJ9cokb5KNBN1445Z7lNV5Mz8KiiL0vHUOhGkchbWGaSOJ2FH2tPSCpwIsPOnkozhhSJ_D5sWYv7PJC_WCADYu8MQ8P9iicwafJBFxSxI8HSjpz-ByJZuCLZGfCzngi-dVS9akrJgV7edhOvDe5eMV9Ywv4PzCUtvAbaEPV8xeo2acVcGl5ouy9I0LO8n6tzWfDZwxVw2idu2KNK1ZaNp2uIsz1n7riEBOYAt8wwQYXHhuBC6nkPILiJOMlWZx7RoJ3Euk4x1Vbz3fYLsZnOmR-NQsdQqWWmLY6qevoPdGfOVDOLYZQLHuT4I1E9FYjRfmVe9jJTdR24LBaQZ__T2jEAA%3D%3D'},
],},
],},
{id: '42', name: 'Natural Minor 3nps', slug: 'library/7-note-scales/natural-minor-3nps', queryString: 'comp=XQAAgAB9AwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7GAWQHLNC2RMqSCvrDWnHO2HLdVs3_RUh6Rqs_Ld0OFPQ28cUV-tVonVmCp3MduUBP302f2SSjO6FLslD8jhnVJRRP5tZZVY4aH74muzJJht2-aMz-Cy4Fj6j-4YJYFDACplrfIxb5yUr4E5r5PmA4d9ePxByfNEnLjxOgpKAxCl0MHY5ZjtMPGxshhg-U1lGhoyt14Dz8XNX3ThgYH_bR7gAA%3D%3D'},
{id: '43', name: 'Harmonic Minor 3nps', slug: 'library/7-note-scales/harmonic-minor-3nps', queryString: 'comp=XQAAgAB-AwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7HWiBomn00_mULEoR6YKLv04fTh5LNRfzw7nB-qUw3zDKormkE0ufmMxxcymOWZKJ0yUsTohRbXvdai3abG-FuWYJGhpMSRNJdRCPUl1OUqLOY4Qr_YD1qbRtRSFMMg2E5OML6fW92t9ACGmcJCtc7PdgAOg0rWvrJgRuNacGkVgXccNKBauYKEMPk9HdUhg7s2Gup57or6MksMlqj50CfsHESA%3D'},
{id: '55', name: 'Harmonic Minor CAGED positions', slug: 'library/7-note-scales/harmonic-minor-caged-positions', queryString: 'comp=XQAAgABGAwAAAAAAAAAznMOiaQTek9SXYNXSLuBa7HWiBomn00_mULEoR6YKLv04fTh5LNQfTHUjW_R9cw5jCsjZyXpkRnV4mMzWitmqbacikvi3JRZ5Y5mBkzS86ejy5i-y4Qh8M6ggRnK03lIjhUzg5OEtaNmR5M0oumwwakY1BfBU2tFQJlDovlrsuOKmzyMmM6Nvu_gJecwmLo_3QGRM7IUEmnpjOxU3u8rzxneV45VeS3aCWzGu84rp-tyoeF-OwFt6ut4_6f8VE-3l2IVVJRZLqVXTsYR0wxSA6uf_RSbLRyK3YR3qQeQ8cIu52ZHZO2arMDnVom_wWk6MKwc-IefgkNFAPf_WlgKg'},
{id: '56', name: 'Phrygian Dominant CAGED positions', slug: 'library/7-note-scales/phrygian-dominant-caged-positions', queryString: 'comp=XQAAgAAoAwAAAAAAAAAznMOiaQTek9SXYNXSLuP-JOGD2JjGZPL31sN9OBiujjF984NcMbm5rs9rzDhaJWOtXHhK6RhjD53AGnwZp7y75JOjc7ufvhyK2FBCFA3KAQlnEs8T9UxJ9yRUCh2GYLShkIs9IDXwrlSwZGCzbhzyQPXD1CMfWTe888FSy9UIaU6L7hCUxXZjBv_Wlz8YmJwZn9xPUEz8mxnOVx0VNrz1hfy0kkCzXTK0FsjN4PtYuXhArwVk60SG8muSMlkwK8YoOZ2jZ7xYBnjPyey2MZgcJmL9lyojNszp0oEeVjec12_Z2qlzUNZuSXpV3iNA4cVh-p778A%3D%3D'},
{id: '44', name: 'Melodic Minor 3nps', slug: 'library/7-note-scales/melodic-minor-3nps', queryString: 'comp=XQAAgAAdBAAAAAAAAAAznMOiaQTek9SXYNXSLuBa7GAWQH_shl55UfTgJrNCqxMbd9-O-whBhb6cACNX3g7V-A5FzlnfdsXouCMvXbjcZ1J65wXvtpX9o_i2ACL6Klip2kl3fpmKyjhV_cvNBkZqHA4lAvPyECSyAdYuvVn5eHBQOKsUZ1R65a-JUu3H-vlV6Jy4M05Mr0bmCVc90La9FRaa63Kr9Dk-Wijg3fbQy_Ng0UBTtKY-8bmHlyYT9HWhH46i53SEwhcUkL8qHU5xasDPHqXNqLmdVvBntvQlc9uaRchp-5gy3z2E-S6wySqns6TOV_iCF5giTzlh9V0H_L0YqliHGJasyMocSdSKsA398Ocd'},
],},
];

export default library;
