import { useState, useEffect } from "react";

const NotePlayer = ({ pitch }) => {
  const [audioContext, setAudioContext] = useState(null);
  const [sampleB0, setSampleB0] = useState(null);
  const [sampleE1, setSampleE1] = useState(null);
  const [sampleE2, setSampleE2] = useState(null);
  const [sampleA2, setSampleA2] = useState(null);
  const [sampleD3, setSampleD3] = useState(null);
  const [sampleG3, setSampleG3] = useState(null);
  const [sampleC4, setSampleC4] = useState(null);
  const [sampleF4, setSampleF4] = useState(null);
  const [sampleBf4, setSampleBf4] = useState(null);
  const [sampleEf5, setSampleEf5] = useState(null);
  const [sampleAf5, setSampleAf5] = useState(null);

  useEffect(() => {
    const audioCtx = new AudioContext();
    setAudioContext(audioCtx);

    async function loadSample(url, setSample) {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      audioCtx.decodeAudioData(arrayBuffer, (buffer) => {
        setSample(buffer);
      });
    }

    loadSample("samples/b0.mp3", setSampleB0);
    loadSample("samples/e1.mp3", setSampleE1);
    loadSample("samples/e2.mp3", setSampleE2);
    loadSample("samples/a2.mp3", setSampleA2);
    loadSample("samples/d3.mp3", setSampleD3);
    loadSample("samples/g3.mp3", setSampleG3);
    loadSample("samples/c4.mp3", setSampleC4);
    loadSample("samples/f4.mp3", setSampleF4);
    loadSample("samples/bf4.mp3", setSampleBf4);
    loadSample("samples/ef5.mp3", setSampleEf5);
    loadSample("samples/af5.mp3", setSampleAf5);

    return () => {
      audioCtx.close();
    };
  }, []);

  useEffect(() => {
    const playSample = (pitch) => {
      if (!audioContext) {
        setAudioContext(new AudioContext());
      }

      let sample = sampleE2;
      let pitchRate = 1.0;
      let steps = 0;
      if (pitch < 23) {
        return;
      } else if (pitch < 28) {
        sample = sampleB0;
        steps = pitch - 23;
      } else if (pitch < 40) {
        sample = sampleE1;
        steps = pitch - 28;
      } else if (pitch < 45) {
        sample = sampleE2;
        steps = pitch - 40;
      } else if (pitch < 50) {
        sample = sampleA2;
        steps = pitch - 45;
      } else if (pitch < 55) {
        sample = sampleD3;
        steps = pitch - 50;
      } else if (pitch < 60) {
        sample = sampleG3;
        steps = pitch - 55;
      } else if (pitch < 65) {
        sample = sampleC4;
        steps = pitch - 60;
      } else if (pitch < 70) {
        sample = sampleF4;
        steps = pitch - 65;
      } else if (pitch < 75) {
        sample = sampleBf4;
        steps = pitch - 70;
      } else if (pitch < 80) {
        sample = sampleEf5;
        steps = pitch - 75;
      } else {
        sample = sampleAf5;
        steps = pitch - 80;
      }

      for (let n = 0; n < steps; n++) {
        pitchRate = pitchRate * 1.05946309436;
      }

      //console.log("pitch=%d, steps=%d, rate=%f", pitch, steps, pitchRate);

      const source = audioContext.createBufferSource();
      source.buffer = sample;

      // Create a GainNode to control the volume
      const gainNode = audioContext.createGain();
      gainNode.gain.value = 0.5; // Set the volume to 50% (0.5)

      source.connect(gainNode); // Connect the source to the gain node
      gainNode.connect(audioContext.destination); // Connect the gain node to the audio context's destination

      source.playbackRate.value = pitchRate;
      source.start();
      source.stop(audioContext.currentTime + 1);
    };

    playSample(pitch);
  }, [
    pitch,
    audioContext,
    sampleB0,
    sampleA2,
    sampleE2,
    sampleE1,
    sampleD3,
    sampleG3,
    sampleC4,
    sampleF4,
    sampleBf4,
    sampleEf5,
    sampleAf5,
  ]);

  return <div />;
};

export default NotePlayer;
