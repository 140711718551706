import { useState, useEffect, useCallback, useRef } from "react";
import NotePlayer from "./NotePlayer";
import { useDispatch, useSelector } from "react-redux";
import classes from "./EarTrainerPage.module.css";
import EarTrainerSetup from "./EarTrainerSetup/EarTrainerSetup";
import ChartDataToSVG from "../code/ChartDataToSVG";
import ChartEditor from "../CreateScalePage/ChartEditor/ChartEditor";
import EarTrainerChart from "./EarTrainerChart.js";
import { mainActions } from "../../store";
import Card from "../UI/Card";
import DronePlayer from "./DronePlayer";
import Button from "../UI/Button";
import RequestSupportCard from "../UI/RequestSupportCard";
import BottomMenu from "../BottomMenu/BottomMenu";
import { Helmet, HelmetProvider } from "react-helmet-async";

/* Walks through the chart creating a list of pitches based on the selected notes */
const CreateSelectionList = (intervalNames, chartData, pitchOffset, tuning) => {
  let retlist = [];
  let rootlist = [];

  let strOffset = 0;
  for (let n = 0; n < chartData.length; n++) {
    for (let f = 0; f < chartData[n].length; f++) {
      const note = strOffset + chartData[n][f][0] + pitchOffset;
      if (note % 12 === 0) {
        rootlist.push(note);
      }
      retlist.push([note, intervalNames[note % 12], n, chartData[n][f][0]]);
    }
    strOffset += tuning[n];
  }
  return [rootlist, retlist];
};

const intervalNames = [
  "Root",
  "Minor 2nd",
  "Major 2nd",
  "Minor 3rd",
  "Major 3rd",
  "Perfect 4th",
  "Aug 4/ Dim 5th",
  "Perfect 5th",
  "Minor 6th",
  "Major 6th",
  "Minor 7th",
  "Major 7th",
];

const EarTrainerPage = () => {
  const [pageState, setPageState] = useState("create charts");
  const [lastState, setLastState] = useState("none");

  const [playPitch, setPlayPitch] = useState(false);
  const [lastPlayedInterval, setLastPlayedInterval] = useState();

  const timeoutIdRef = useRef(null);
  const rootTimeoutIdRef = useRef(null);

  const [droneNote] = useState(0);
  const [droneOn] = useState(false);

  // the index of the current chart taken from createdData
  const earTrainerChart = useSelector((state) => state.earTrainerChart);
  const earTrainerQuestionType = useSelector(
    (state) => state.earTrainerQuestionType
  );
  const earTrainerShowNotes = useSelector((state) => state.earTrainerShowNotes);
  const earTrainerMode = useSelector((state) => state.earTrainerMode);

  const earTrainerShapeChange = useSelector(
    (state) => state.earTrainerShapeChange
  );
  const earTrainerRootNote = useSelector((state) => state.earTrainerRootNote);
  const earTrainerRootOctaveSelect = useSelector(
    (state) => state.earTrainerRootOctaveSelect
  );
  const earTrainerIgnoreOctaves = useSelector(
    (state) => state.earTrainerIgnoreOctaves
  );
  const [shapeChangeCount, setShapeChangeCount] = useState(0);
  const [questionPitch, setQuestionPitch] = useState(0);
  const [questionInterval, setQuestionInterval] = useState("");
  const [questionText, setQuestionText] = useState(<>&nbsp;</>);

  const tuning = useSelector((state) => state.tuning);
  const midiRootNote = useSelector((state) => state.midiRootNote);

  // this is the best fit set of charts data
  const createdData = useSelector((state) => state.createdData);
  const dispatch = useDispatch();
  const [pitchOffset, setPitchOffset] = useState(0);
  const [questionList, setQuestionList] = useState();
  const [svgData, setSvgData] = useState({ image: "" });
  const [octave, setOctave] = useState(0);
  const [shiftPressed, setShiftPressed] = useState(false);

  const [rootNote, setRootNote] = useState();
  /*********************************************************8
   * Get the state of the Shift Key.
   */
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  /***********************************************
   * play() Function Definition
   */
  const play = useCallback(
    (pitch) => {
      // Clear the previous timeout, if there was one
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      // Set the new pitch and create a timeout to clear it
      setPlayPitch(pitch);
      timeoutIdRef.current = setTimeout(() => {
        setPlayPitch(0);
        timeoutIdRef.current = null; // Clear the reference when the timeout is done
      }, 500);
    },
    [] // No dependencies needed
  );

  /*****************************************************
   * handleNoteClick() function
   */
  const handleNoteClick = useCallback(
    (id, string, fret, shift) => {
      // tell google about the click.
      window.gtag("event", "click", {
        event_category: "Image",
        event_label: "EarTrainingImage",
      });

      // calculate the pitch from the string, fret and tuning
      let pitch = fret + pitchOffset;
      for (let n = 0; n < string; n++) {
        pitch += tuning[n];
      }
      setLastPlayedInterval(intervalNames[pitch % 12]);

      // if were just playing notes, go for it.
      if (shiftPressed) {
        play(pitch + earTrainerRootNote + octave);
      } else if (earTrainerMode === 0) {
        play(pitch + earTrainerRootNote + octave);
      } else if (pageState === "wait for guess") {
        setLastState("wait for guess");

        let passed = false;
        if (earTrainerIgnoreOctaves) {
          passed = pitch % 12 === questionPitch % 12;
        } else {
          passed = pitch === questionPitch;
        }
        // if we're in test conditions only play the note if it's correct
        if (passed) {
          setPageState("play question note");
          //setGuessedPitch(pitch + earTrainerRootNote + octave);
        }
      }
      return;
    },
    [
      earTrainerMode,
      earTrainerRootNote,
      earTrainerIgnoreOctaves,
      octave,
      pageState,
      pitchOffset,
      play,
      questionPitch,
      tuning,
      shiftPressed,
    ]
  );

  /*******************************************************************
   * Changes to these components force a full recalculation
   */
  useEffect(() => {
    setLastState("");
    setPageState("create charts");
  }, [
    createdData,
    earTrainerChart,
    earTrainerMode,
    earTrainerRootNote,
    midiRootNote,
    earTrainerRootOctaveSelect,
  ]);

  /********************************************************************
   * These just redraw the screen.
   */
  useEffect(() => {
    setLastState("");
    setPageState("refresh charts");
  }, [earTrainerShowNotes, earTrainerQuestionType]);

  /*******************************************************************
   * The Chart Creation State.
   */
  useEffect(() => {
    const pos = createdData[earTrainerChart];
    if (pos && tuning && earTrainerRootNote) {
      if (
        (pageState === "create charts" || pageState === "refresh charts") &&
        lastState !== pageState
      ) {
        setLastState(pageState);
        if (earTrainerMode !== 0) {
          if (pageState === "create charts") {
            setPageState("play root note");
          } else {
            setPageState("wait for guess");
          }
        }
        console.log("to create charts");

        setSvgData(
          ChartDataToSVG(pos.chartData, pos.maxFret, earTrainerShowNotes)
        );

        setPitchOffset(pos.notesOffset);

        const [roots, ql] = CreateSelectionList(
          intervalNames,
          pos.chartData,
          pos.notesOffset,
          tuning
        );
        //console.log(ql);
        setQuestionList(ql);

        const delta =
          ql[0][0] + earTrainerRootNote - midiRootNote - pos.chartData[0][0][0];
        console.log(
          ql[0][0] + earTrainerRootNote,
          earTrainerRootNote,
          midiRootNote,
          delta
        );

        let root = 0;
        if (delta >= 24) {
          root = -24;
        } else if (delta >= 12) {
          root = -12;
        } else if (delta >= 0) {
          root = 0;
        } else if (delta >= -12) {
          root = 12;
        } else if (delta >= -24) {
          root = 24;
        }
        setOctave(root);

        const rootSelection = earTrainerRootOctaveSelect ? roots.length - 1 : 0;
        setRootNote(earTrainerRootNote + roots[rootSelection] + root);
      }
    }
  }, [
    createdData,
    earTrainerChart,
    earTrainerMode,
    earTrainerRootNote,
    earTrainerRootOctaveSelect,
    earTrainerShowNotes,
    lastState,
    midiRootNote,
    pageState,
    tuning,
  ]);

  /**********************************************************
   * The Play Root Note State
   */
  useEffect(() => {
    if (pageState === "play root note" && lastState !== "play root note") {
      setLastState("play root note");
      console.log("to play root note");

      play(rootNote);

      // Clear the previous timeout, if there was one
      if (rootTimeoutIdRef.current) {
        clearTimeout(rootTimeoutIdRef.current);
      }

      // Set the new pitch and create a timeout to clear it
      //setPlayPitch(pitch);
      rootTimeoutIdRef.current = setTimeout(() => {
        setPageState("play question note");
        rootTimeoutIdRef.current = null; // Clear the reference when the timeout is done
      }, 1000);
    }
  }, [pageState, lastState, play, rootNote, earTrainerMode]);

  useEffect(() => {
    return () => {
      clearTimeout(rootTimeoutIdRef.current);
    };
  }, []);

  /**********************************************************
   * The Play Question Note State
   */
  useEffect(() => {
    const createAndPlayQuestionNote = () => {
      console.log("createAndPlayQuestionNote");

      let randomValue = 0;
      for (;;) {
        const randomIndex = Math.floor(Math.random() * questionList.length);
        randomValue = questionList[randomIndex];
        if (randomValue[0] !== questionPitch) break;
      }
      //console.log(randomValue, earTrainerRootNote, octave);
      setQuestionPitch(randomValue[0]);
      setQuestionInterval(randomValue[1]);
      if (earTrainerMode !== 0) {
        play(randomValue[0] + earTrainerRootNote + octave);
      }
    };

    if (
      pageState === "play question note" &&
      lastState !== "play question note"
    ) {
      setLastState("play question note");
      console.log("to play question note");

      // if we dont ever want to automatically change the shape, then just keep
      // the previous value
      if (earTrainerShapeChange === 0) {
        createAndPlayQuestionNote();
        setPageState("wait for guess");
      } else if (shapeChangeCount < earTrainerShapeChange) {
        createAndPlayQuestionNote();
        setPageState("wait for guess");
        setShapeChangeCount((prev) => {
          return prev + 1;
        });
      } else {
        setPageState("create charts");
        setShapeChangeCount((prev) => {
          const shape = Math.floor(Math.random() * createdData.length);
          dispatch(mainActions.setEarTrainerChart(shape));

          const key = Math.floor(Math.random() * 12);
          dispatch(mainActions.setEarTrainerRootNote(key + 40));
          let next = prev + 1;
          if (next > earTrainerShapeChange) {
            // if we're ready to move on...
            next = 0;
          }
          return 0;
        });
      }
    }
  }, [
    createdData.length,
    dispatch,
    earTrainerMode,
    earTrainerQuestionType,
    earTrainerRootNote,
    earTrainerShapeChange,
    lastState,
    octave,
    pageState,
    play,
    questionList,
    questionPitch,
    shapeChangeCount,
  ]);

  useEffect(() => {
    if (pageState === "play root note") {
      setQuestionText(
        <>
          Playing the&nbsp;<b>Root</b>
        </>
      );
    } else if (earTrainerMode === 0) {
      if (lastPlayedInterval) {
        setQuestionText(
          <>
            Playing the&nbsp;<b>{lastPlayedInterval}</b>
          </>
        );
      } else {
        setQuestionText(<>&nbsp;</>);
      }
    } else if (earTrainerQuestionType !== 2) {
      setQuestionText(
        <>
          Can you find the&nbsp;<b>{questionInterval}</b>?
        </>
      );
    } else {
      setQuestionText(<>Can you find the played note?</>);
    }
  }, [
    pageState,
    earTrainerQuestionType,
    earTrainerMode,
    lastPlayedInterval,
    questionInterval,
  ]);

  const onHearRoot = (event) => {
    play(rootNote);
    console.log(rootNote);
  };

  const onHearQuestion = (event) => {
    //console.log(questionPitch);
    play(questionPitch + earTrainerRootNote + octave);
  };

  return (
    <div className={classes.background}>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Guitar Charts - Ear Trainer</title>
          <meta
            name="description"
            content="Guitar Charts Designer is a web app that lets you create and print custom guitar charts and diagrams."
          />
        </Helmet>
      </HelmetProvider>

      <Card>
        <h1 className={classes.h1}>Guitar Charts - Ear Trainer</h1>
      </Card>
      <RequestSupportCard />
      <Card>
        <ChartEditor />
      </Card>
      <Card>
        <EarTrainerSetup />
      </Card>
      <Card>
        <table>
          <tbody>
            <tr><td colSpan={2} style={{ textAlign: "center" }}>
              Click on the chart below to play the notes
              </td> </tr>
            <tr>
              <td colSpan={2} style={{ textAlign: "center" }}>
                {questionText}
              </td>
            </tr>
            {earTrainerMode ? (
              <tr>
                <td>
                  <Button onClick={onHearRoot}>Hear The Root</Button>
                </td>
                <td>
                  <Button onClick={onHearQuestion}>Hear The Question</Button>
                </td>
              </tr>
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </Card>
      <Card>
        <div className={classes.imageGrid}>
          <EarTrainerChart
            index={0}
            image={svgData.image}
            onClick={handleNoteClick}
            stringCount={svgData.strings}
            maxFret={svgData.frets}
          />
        </div>
      </Card>
      <Card>
        <BottomMenu />
      </Card>
      <NotePlayer pitch={playPitch} />
      <DronePlayer note={droneNote} keydown={droneOn} />

      {/* put some space in the bottom of the chart so you can scroll the ear trainer chart into view */}
      <div style={{ height: "100vh" }} />
    </div>
  );
};

export default EarTrainerPage;
