import highlightClick from "./highlightClick";

/* Draws a single Guitar chart in a Div 

If the user makes a valid click on the chart, then calls the callback.
      onClick(index, string, fret);
*/

const EarTrainerChart = ({ index, image, stringCount, maxFret, onClick }) => {
  //const [highlightStyle, setHighlightStyle] = useState(null);
  const src = `data:image/svg+xml;utf8,${encodeURIComponent(image)}`;

  const clickHandler = (event) => {
    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;
    const fretInc = event.target.clientWidth / (maxFret + 1);
    let target = fretInc / 2.0;

    let fret = -1;
    for (let n = 0; n < maxFret; n++) {
      if (x > target && x < target + fretInc) {
        fret = n;
        break;
      }
      target += fretInc;
    }

    const stringInc = event.target.clientHeight / (stringCount + 1);
    target = stringInc / 2.0;
    let string = -1;
    for (let n = 0; n < stringCount; n++) {
      if (y > target && y < target + stringInc) {
        string = stringCount - n - 1;
        break;
      }
      target += stringInc;
    }
    // console.log(`${event.target.clientWidth} ${event.target.clientHeight} ${maxFret} ${stringCount} ${string} ${fret}`);

    if (string >= 0 && fret >= 0) {
      const rect = event.target.getBoundingClientRect();

      const x = (fret + 1) * fretInc + rect.left + window.scrollX;
      const y = (stringCount - string) * stringInc + rect.top + window.scrollY;
      //console.log(fretInc, maxFret, x,y,rect);
      highlightClick(x, y);

      onClick(index, string, fret, event.shiftKey);
    }
  };

  return (
    <div id={`${index}`} style={{ textAlign: "left" }}>
      <img
        id="earTrainerChart"
        draggable="false"
        onClick={clickHandler}
        src={src}
        alt="Guitar Trainer Chart"
        style={{
          objectFit: "cover",
        }}
      />
    </div>
  );
};

export default EarTrainerChart;
