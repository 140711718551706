import React, { useCallback, useEffect } from "react";
import classes from "./App.module.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PickleToQueryString from "./store/PickleToQueryString";
import CreateScalePage from "./components/CreateScalePage/CreateScalePage";
import MenuBar from "./components/MenuBar/MenuBar";
import RefineScalePage from "./components/RefineScalePage/RefineScalePage";
import PublishScalePage from "./components/PublishScalePage/PublishScalePage";
import UnpickleFromQueryString from "./store/UnpickleFromQueryString";
import SupportMePage from "./components/SupportMePage/SupportMePage";
import DownloadScalePage from "./components/DownloadScalePage/DownLoadScalePage";
import LibraryPage from "./components/LibraryPage/LibraryPage";
import { mainActions } from "./store";
import PrivacyPage from "./components/PrivacyPage/PrivacyPage";
import PreviewScalePage from "./components/PublishScalePage/PreviewScalePage";
import TermsOfUsePage from "./components/TermsOfUsePage/TermsOfUsePage";
import EarTrainerPage from "./components/EarTrainerPage/EarTrainerPage";
import RenderData from "./store/RenderData";
import FretboardPage from "./components/FretboardPage/FretboardPage";
import BlogPage from "./components/Blog/BlogPage";
import MetronomePage from "./components/MetronomePage/MetronomePage";

function App({ page }) {
  // set a default state
  const menuSelected = useSelector((state) => state.menuSelected);
  const isQueryStringUnpacked = useSelector(
    (state) => state.isQueryStringUnpacked
  );

  //const [menuSelected, setMenuSelected] = useState(1);

  const location = useLocation();
  const dispatch = useDispatch();

  const setMenuSelected = useCallback(
    (selected) => {
      dispatch(mainActions.setMenuSelected(selected));
    },
    [dispatch]
  );

  useEffect(() => {
  }, []);

  useEffect(() => {
    // if we have data then go to refine.
    if (page === "home" && location.search.length && !isQueryStringUnpacked) {
      setMenuSelected(2);
    } else if (menuSelected === 0) {
      setMenuSelected(1);
    }
  }, [location, page, menuSelected, setMenuSelected, isQueryStringUnpacked]);

  useEffect(() => {
    if (page === "home") {
      if (menuSelected === 2 && !isQueryStringUnpacked) {
        UnpickleFromQueryString(location.search, dispatch);
        dispatch(mainActions.setIsQueryStringUnpacked(true));
      }
    }
    if (page === "publish") {
      UnpickleFromQueryString(location.search, dispatch);
    }
  }, [menuSelected, page, isQueryStringUnpacked, dispatch, location.search]);

  const onClickPublishHandler = () => {
    const url = `/${location.search}`;
    window.location = url;
  };

  const homePageActive = page === "home" && menuSelected === 1;
  const libraryPageActive = page === "library";
  const refineScalePageActive = page === "home" && menuSelected === 2;
  const previewScalePageActive = page === "home" && menuSelected === 3;
  const downloadScalePageActive = page === "home" && menuSelected === 4;
  const trainingPageActive = page === "training";
  const fretboardPageActive = page === "fretboard";
  const blogPageActive = page === "blog";
  const supportMePageActive = page === "support";
  const publishScalePageActive = page === "publish";
  const privacyPageActive = page === "privacy";
  const termsPageActive = page === "terms";
  const metronomePageActive = page === "metronome";

  useEffect(() => {
    // refine data can only be regenerated when the Create Menu is active
    dispatch(mainActions.setProtectRefineData(!homePageActive));

  },[homePageActive,dispatch]);

  return (
    <>
      {!publishScalePageActive && (
        <>
          <MenuBar
            page={page}
            selected={menuSelected}
            onMenuClick={setMenuSelected}
          />
          <div className={classes.body}>
            {homePageActive && <CreateScalePage />}
            {libraryPageActive && <LibraryPage />}
            {refineScalePageActive && <RefineScalePage />}
            {supportMePageActive && <SupportMePage />}
            {previewScalePageActive && (
              <PreviewScalePage onClick={onClickPublishHandler} />
            )}
            {downloadScalePageActive && <DownloadScalePage />}
            {privacyPageActive && <PrivacyPage />}
            {termsPageActive && <TermsOfUsePage />}
            {trainingPageActive && <EarTrainerPage />}
            {fretboardPageActive && <FretboardPage />} 
            {blogPageActive && <BlogPage />} 
            {metronomePageActive && <MetronomePage />} 
          </div>
        </>
      )}
      {publishScalePageActive && (
          <PublishScalePage onClick={onClickPublishHandler} />
      )}
      {menuSelected === 2 && <PickleToQueryString />}
      <RenderData />
    </>
  );
}

export default App;
