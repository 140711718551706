import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import GenerateExtendedScaleNotes from "../code/GenerateExtendedScaleNotes";
import GeneratePosition from "../code/GeneratePosition";
import ChartDataToSVG from "../code/ChartDataToSVG";
import { Canvg } from "canvg";
import { Helmet, HelmetProvider } from "react-helmet-async";

const DownloadScalePage = () => {
  const refineData = useSelector((state) => state.refineData);
  const scaleData = useSelector((state) => state.intervals);
  const scaleColor = useSelector((state) => state.intervalColors);
  const tuning = useSelector((state) => state.tuning);

  const s = GenerateExtendedScaleNotes(scaleData, scaleColor);

  const elements = refineData.perChartData.map((chartData, index) => {
    const pos = GeneratePosition(
      tuning,
      chartData.notesPerString,
      s.slice(chartData.notesOffset)
    );
    const svg = ChartDataToSVG(pos.chartData, pos.maxFret);
    return svg.image;
  });

  const zipFiles = async (svgDataArray) => {
    const zip = new JSZip();
    svgDataArray.forEach((svgData, index) => {
      zip.file(`file${index}.svg`, svgData);
    });
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "files.zip");
  };

  const downloadSvgZipHandler = () => {
    zipFiles(elements);
  };

  async function convertSvgToPng(svgData) {
    // Create a new canvas element
    const canvas = document.createElement("canvas");

    // Parse the SVG data into a DOM element using DOMParser
    const svg = new DOMParser().parseFromString(
      svgData,
      "image/svg+xml"
    ).documentElement;

    // Set the canvas dimensions to match the SVG element's dimensions
    canvas.width = svg.getAttribute("width");
    canvas.height = svg.getAttribute("height");

    // Render the SVG element onto the canvas using Canvg
    const ctx = canvas.getContext("2d");
    await Canvg.from(ctx, svgData, Canvg.presets.offscreen()).render();

    // Convert the canvas to a PNG data URL
    const pngData = canvas.toDataURL("image/png");

    // Extract the base64-encoded image data from the data URL
    return pngData.split(",")[1];
  }

  const downloadPngZipHandler = async () => {
    //console.log(elements[0]);
    const png = await convertSvgToPng(elements[0]);
    console.log(png);
  };

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Guitar Charts Designer - Download</title>
          <meta
            name="description"
            content="Guitar Charts Designer is a web app that lets you create and print custom guitar charts and diagrams."
          />
        </Helmet>
      </HelmetProvider>
      <h1>Guitar Chart Designer - Download</h1>
      Download Stuff
      <button onClick={downloadSvgZipHandler}>Download SVG zip</button>
      <button onClick={downloadPngZipHandler}>Download PNG zip</button>
    </div>
  );
};

export default DownloadScalePage;
